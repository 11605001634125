import React, { useEffect , useState , useMemo } from "react";
import DashboardHeader from "../Components/TailwindComponent/DashboardHeader";
import SidebarWrapper from "../Components/TailwindComponent/SidebarWrapper";
import Table from "../Components/TailwindComponent/Table";
import Input from "../Components/TailwindComponent/Input";
import DatePicker from "../Components/TailwindComponent/DatePicker";
import Pagination from "../Components/TailwindComponent/Pagination";

const ScheduledMailScreen = () => {
  
  const [data, setData] = useState([]);
  const [maxPage, setMaxPage] = useState(1);
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(1);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [searchTerm, setSearchTerm] = useState("");  

  useEffect(() => {
    var date = new Date();
    date.setDate(date.getDate());

    let startDateformat =
      date.getDate().toString().length === 1
        ? "0" + date.getDate()
        : date.getDate();

    const monthFormat =
      date.getMonth() + 1 > 9
        ? date.getMonth() + 1
        : "0" + (date.getMonth() + 1);

    var finalDate =
      date.getFullYear() + "-" + monthFormat + "-" + startDateformat;
    setStartDate(finalDate);

    var todayDate = new Date();
    todayDate.setDate(todayDate.getDate()+30);

    let endDateformat =
      todayDate.getDate().toString().length === 1
        ? "0" + todayDate.getDate()
        : todayDate.getDate();
    const todaymonthFormat =
      todayDate.getMonth() + 1 > 9
        ? todayDate.getMonth() + 1
        : "0" + (todayDate.getMonth() + 1);

    var finalToday =
      todayDate.getFullYear() + "-" + todaymonthFormat + "-" + endDateformat;
    setEndDate(finalToday);
    getScheduleList(finalDate, finalToday, page);
  }, []);

  const getScheduleList = async (end,today) => {
    let fetchedData = await fetch(
      "https://bulk.sasone.in/emailapi/v1/bulkemail/ScheduledEmailListSearching",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token 8cb23c1e5efb27dbadefabe601226a4264f59daa",
        },
        body:JSON.stringify({
          Searching: searchTerm,
          page: page,
          StartDate:end ? end : startDate,
          EndDate:today ? today : endDate
        })
      }
    );

    let parsedData = await fetchedData.json();
    if(parsedData.status === "Success" && parsedData.status_code === 200){
      
      setData(parsedData.data);
      setTotalCount(parsedData.totalCount);
      let data = parsedData.totalCount / 20;
      setMaxPage(Math.ceil(data));
    }else{
      setData([]);
        setMaxPage(1);
    }
  };

  useMemo(()=>{
      getScheduleList();
  },[page]);

  const pageHandler = (value) => {
      setPage(value);
  };

  return (
    <div>
      <SidebarWrapper>
        <DashboardHeader Heading={"Scheduled Emails"} />
        
        <div className="my-3 flex flex-col xl:flex-row items-start sm:items-center justify-between px-4 sm:px-0">
          <DatePicker
            searchTerm={searchTerm}
            endDate={endDate}
            startDate={startDate}
            StartOnChange={(e) => {
              setStartDate(e.target.value);
            }}
            EndOnChange={(e) => {
              setEndDate(e.target.value);
            }}
            // min={minimumDate}
            onClickBtn={()=>{page === 1 ? getScheduleList() : setPage(1)}}
          />
          <Input type="SearchBox" onChange={setSearchTerm} value={searchTerm} onClick={()=>{page === 1 ? getScheduleList() : setPage(1)}} />
        </div>

        <Table people={data} tableType={"Scheduled"} />
        <Pagination 
        maxPages={maxPage} 
        hitPage={pageHandler} 
        pageNum={page} 
        dataLength={data === null ? 0 : data.length} 
        totalCount={totalCount} 
        />
      </SidebarWrapper>
    </div>
  );
};

export default ScheduledMailScreen;
