import React, { useState, useEffect, useMemo } from "react";
import Table from "../Components/TailwindComponent/Table";
import SimpleNavbar from "../Components/TailwindComponent/Navbar";
import SidebarWrapper from "../Components/TailwindComponent/SidebarWrapper";
import DashboardHeader from "../Components/TailwindComponent/DashboardHeader";
import Modal from "../Components/TailwindComponent/Modal";
import SuccessMessage from "../Components/TailwindComponent/SuccessMessage";
import Pagination from "../Components/TailwindComponent/Pagination";
import Input from "../Components/TailwindComponent/Input";
import DatePicker from "../Components/TailwindComponent/DatePicker";

const TemplatesList = () => {
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [show, setShow] = useState(false);
  const [apiFailled, setApiFailled] = useState(false);
  const [alertMessage, setAlertMessage] = useState("Status Updated");
  const [maxPage, setMaxPage] = useState(1);
  const [page, setPage] = useState(1);
  const [searchPage, setSearchPage] = useState(1);
  const [totalCount, setTotalCount] = useState(1);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [searchTerm, setSearchTerm] = useState("");

  // useEffect(() => {
  //   defaultDate();
  // }, []);

  useMemo(() => {
    setTimeout(() => {
      setShow(false);
    }, 5000);
  }, [show]);

  useEffect(() => {
    var date = new Date();
    date.setDate(date.getDate() - 30);

    let startDateformat =
      date.getDate().toString().length === 1
        ? "0" + date.getDate()
        : date.getDate();

    const monthFormat =
      date.getMonth() + 1 > 9
        ? date.getMonth() + 1
        : "0" + (date.getMonth() + 1);

    var finalDate =
      date.getFullYear() + "-" + monthFormat + "-" + startDateformat;
    setStartDate(finalDate);

    var todayDate = new Date();
    todayDate.setDate(todayDate.getDate());

    let endDateformat =
      todayDate.getDate().toString().length === 1
        ? "0" + todayDate.getDate()
        : todayDate.getDate();
    const todaymonthFormat =
      todayDate.getMonth() + 1 > 9
        ? todayDate.getMonth() + 1
        : "0" + (todayDate.getMonth() + 1);

    var finalToday =
      todayDate.getFullYear() + "-" + todaymonthFormat + "-" + endDateformat;
    setEndDate(finalToday);
    TemplateList(finalDate, finalToday, page);
  }, []);

  const TemplateList = async (end,today) => {
    let fetchedData = await fetch(
      "https://bulk.sasone.in/emailapi/v1/bulkemail/SearchingApiOfTemplate",
      {
        method: "POST",
        headers: {
          Authorization: "Token 8cb23c1e5efb27dbadefabe601226a4264f59daa",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          Searching: searchTerm,
            StartDate: end ? end : startDate,
            EndDate: today ? today : endDate,
            page: page,
        }),
      }
    );

    let parsedData = await fetchedData.json();

    if (parsedData.status_code === 200 && parsedData.status === "Success") {
      setData(parsedData.data);
      setTotalCount(parsedData.totalCount);
      let data = parsedData.totalCount / 20;
      setMaxPage(Math.ceil(data));
    }
  };

  // const SearchTemplateList = async () => {
  //     let fetchedData = await fetch(
  //       "",
  //       {
  //         method: "POST",
  //         headers: {
  //           Authorization: "Token 8cb23c1e5efb27dbadefabe601226a4264f59daa",
  //           "Content-Type": "application/json",
  //         },
  //         body: JSON.stringify({
            
  //         }),
  //       }
  //     );

  //     let parsedData = await fetchedData.json();

  //     if (parsedData.status_code === 200 && parsedData.status === "Success") {
  //       setData(parsedData.data);
  //       setTotalCount(parsedData.totalCount);
  //       let data = parsedData.totalCount / 20;
  //       setMaxPage(Math.ceil(data));
  //     }
  // };

  useMemo(()=>{
    TemplateList();
},[page]);

  const deleteClicked = (tempId) => {
    setDeleteId(tempId);
    setOpen(true);
  };

  const deleteTemplate = async () => {
    let fetchedData = await fetch(
      "https://bulk.sasone.in/emailapi/v1/bulkemail/DeleteTemplateAPI",
      {
        method: "POST",
        headers: {
          Authorization: "Token 8cb23c1e5efb27dbadefabe601226a4264f59daa",
          "Content-Type": "application/json",
        }, 
        body: JSON.stringify({
          EmailTemplateID: deleteId,
        }),
      }
    );

    let parseData = await fetchedData.json();

    if (parseData.status === "Success" && parseData.status_code === 200) {
      setAlertMessage("Template Deleted Successfully!");
      setApiFailled(false);
      setShow(true);
      TemplateList();
    } else {
      setAlertMessage("Status Failed!");
      setApiFailled(true);
      setShow(true);
    }
    setOpen(false);
    setDeleteId("");
  };

  // const defaultDate = () => {
  //   const date = new Date();

  //   let beginDate = `${date.getFullYear()}-${date.getMonth()}-${date.getDate()}`;
  //   let splitOne = beginDate.split("-");

  //   if (splitOne[1].length === 1) {
  //     splitOne[1] = `0${splitOne[1]}`;
  //   }

  //   if (splitOne[2].length === 1) {
  //     splitOne[2] = `0${splitOne[2]}`;
  //   }

  //   let goodBegin = splitOne.join("-");
  //   setStartDate(goodBegin);

  //   let finishDate = `${date.getFullYear()}-${
  //     date.getMonth() + 1
  //   }-${date.getDate()}`;
  //   let splitTwo = finishDate.split("-");

  //   if (splitTwo[1].length === 1) {
  //     splitTwo[1] = `0${splitTwo[1]}`;
  //   }

  //   if (splitTwo[2].length === 1) {
  //     splitTwo[2] = `0${splitTwo[2]}`;
  //   }

  //   let goodFinish = splitTwo.join("-");
  //   setEndDate(goodFinish);
  //   TemplateList(goodBegin,goodFinish);
  // };


  const pageHandler = (value) => {
      setPage(value);
  };

  return (
    <div>
      <SuccessMessage
        show={show}
        apiFailled={apiFailled}
        message={alertMessage}
        onClose={() => {
          setShow(false);
        }}
      />
      <SidebarWrapper>
        <Modal
          type={"Simple alert"}
          setOpen={setOpen}
          desc={"Are you sure you want to proceed"}
          heading={"Delete Template"}
          open={open}
          onCancel={() => {
            setOpen(false);
          }}
          onContinue={() => {
            deleteTemplate();
          }}
        />
        <DashboardHeader Heading={"Templates List"} />
        <SimpleNavbar type={"template"} />

        <div className="my-3 flex flex-col xl:flex-row items-start sm:items-center justify-between px-4 sm:px-0">
          <DatePicker
            endDate={endDate}
            startDate={startDate}
            StartOnChange={(e) => {
              setStartDate(e.target.value);
            }}
            EndOnChange={(e) => {
              setEndDate(e.target.value);
            }}
            onClickBtn={()=>{page === 1 ? TemplateList() : setPage(1)}}
          />

          <Input
            type="SearchBox"
            onChange={setSearchTerm}
            value={searchTerm}
            onClick={()=>{page === 1 ? TemplateList() : setPage(1)}}
          />
        </div>

        <Table data={data} deleteClicked={deleteClicked} />
        <Pagination
          maxPages={maxPage}
          hitPage={pageHandler}
          pageNum={page}
          dataLength={data === null ? 0 : data.length}
          totalCount={totalCount}
        />
      </SidebarWrapper>
    </div>
  );
};

export default TemplatesList;
