import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { RiDeleteBin6Line } from "react-icons/ri";
import DataNotFound from "./DataNotFound";

const Table = (props) => {
  const navigate = useNavigate();
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const sliceUrl = (value) => {
    let newUrlArray = value.split("/");
    let arrayLength = newUrlArray.length;
    return newUrlArray[arrayLength - 1];
  };

  const sliceTime = (value) => {
    let newTimeArray = value.split("T");
    return newTimeArray[1];
  };

  const dateHandler = (date) => {
    const dateObj = new Date(date);
    const day = dateObj.getDate();
    const monthNameLong = dateObj.toLocaleString("en-US", { month: "short" });
    const year = dateObj.getFullYear();
    
    return (day <= 9 ? "0" : "") + day + "-" + monthNameLong + "-" + year;
  };

  // const dateHandler = (date) => {
  //   const dateObj = new Date(date);
  //   const day = dateObj.getDate();
  //   const monthNameLong = dateObj.toLocaleString("en-US", { month: "short" });
  //   const year = dateObj.getFullYear();
  //   const hour = dateObj.getHours();
  //   const month = dateObj.getMonth();
  //   return (day <= 9 ? "0" : "") + day + "-" + monthNameLong + "-" + year + "," + (hour <= 9 ? "0" : "") + hour + ":" + (month <= 9 ? "0" : "") + month;
  // };

  const tableType = (tableType) => {
    switch (tableType) {
      case "Scheduled":
        return (
          <div className="px-4 sm:px-8 lg:px-1 mt-0">
            <div className="mt-0 flex flex-col">
              <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                  <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                    <table className="min-w-full divide-y divide-gray-300">
                      <thead className="bg-gray-50">
                        <tr>
                          <th
                            scope="col"
                            className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                          >
                            Templates
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                          >
                            Contacts
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                          >
                            From_Email
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                          >
                            Count
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                          >
                            Date
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                          >
                            Time
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                          >
                            Delivered
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                          >
                            Opened
                          </th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-200 bg-white">
                        {props.people?.length > 0 ? (
                          props.people?.map((person) => (
                            <tr key={person.EmailScheduleID}>
                              <td
                                className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium sm:pl-6 text-indigo-700 underline hover:text-indigo-500 cursor-pointer"
                                onClick={() =>
                                  navigate(
                                    "/edittemplate/" + person.EmailTemplateID
                                  )
                                }
                              >
                                {person.TemplateName ? person.TemplateName : ""}
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 hover:bg-gray-200">
                                <a href={person.EmailSource}>
                                  {person.EmailSource
                                    ? sliceUrl(person.EmailSource)
                                    : "N/A"}
                                </a>
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                {person.EmailFrom
                                  ? person.EmailFrom
                                  : "N/A"}
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                {person.EmailCount ? person.EmailCount : "N/A"}
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                {dateHandler(person.ScheduledDate)}
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                {person.ScheduledDate
                                  ? sliceTime(person.ScheduledDate)
                                  : "N/A"}
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                {person.DeliveredEmail
                                  ? person.DeliveredEmail
                                  : "00"}
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                {person.openCount ? person.openCount : "00"}
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan={7}>
                              <DataNotFound />
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );

      case "MailHistory":
        return (
          <div className="px-4 sm:px-8 lg:px-1 mt-0">
            <div className="mt-0 flex flex-col">
              <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                  <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                    <table className="min-w-full divide-y divide-gray-300">
                      <thead className="bg-gray-50">
                        <tr>
                          <th
                            scope="col"
                            className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                          >
                            Templates
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                          >
                            Contact
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                          >
                            Email_From
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                          >
                            Count
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                          >
                            Date
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                          >
                            Time
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                          >
                            Delivered
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                          >
                            Opened
                          </th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-200 bg-white">
                        {props.people?.length > 0 ? (
                          props.people?.map((person) => (
                            <tr key={person.KeyIndex}>
                              <td
                                className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-indigo-700 underline hover:text-indigo-500 cursor-pointer"
                                onClick={() =>
                                  navigate(
                                    "/edittemplate/" + person.EmailTemplateID
                                  )
                                }
                              >
                                {person.TemplateName}
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 hover:bg-gray-200">
                                <a href={person.EmailSource}>
                                  {person.EmailSource
                                    ? sliceUrl(person.EmailSource)
                                    : undefined}
                                </a>
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                {person.EmailFrom||"N/A"}
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                {person.EmailCount}
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                {dateHandler(
                                  person.UpdatedAt
                                )}
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                {person.UpdatedAt
                                  ? sliceTime(person.UpdatedAt)
                                  : "N/A"}
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                {person.Delivered}
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                {person.Opened}
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan={7}>
                              <DataNotFound />
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      default:
        return (
          <div className="">
            <table
              className="min-w-full border-separate overflow-scroll"
              style={{ borderSpacing: 0 }}
            >
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-8"
                  >
                    Template Name
                  </th>
                  <th
                    scope="col"
                    className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:table-cell"
                  >
                    Subject
                  </th>
                  <th
                    scope="col"
                    className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                  >
                    Dynamic Field
                  </th>
                  <th
                    scope="col"
                    className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                  >
                    Created At
                  </th>
                  <th
                    scope="col"
                    className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pr-4 pl-3 backdrop-blur backdrop-filter sm:pr-6 lg:pr-8"
                  >
                    <span className="sr-only">Update</span>
                  </th>
                  <th
                    scope="col"
                    className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pr-4 pl-3 backdrop-blur backdrop-filter sm:pr-6 lg:pr-8"
                  >
                    <span className="sr-only">Delete</span>
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white">
                {props.data?.length > 0 ? (
                  props.data?.map((person, personIdx) => (
                    <tr key={person.ID}>
                      <td
                        className={classNames(
                          personIdx !== props.data.length - 1
                            ? "border-b border-gray-200"
                            : "",
                          "py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8 relative"
                        )}
                      >
                        <p className="truncate w-32 cursor-default">
                          {person.TemplateName}
                        </p>
                      </td>
                      <td
                        className={classNames(
                          personIdx !== props.data.length - 1
                            ? "border-b border-gray-200"
                            : "",
                          "whitespace-nowrap px-3 py-4 text-sm text-gray-500 sm:table-cell"
                        )}
                      >
                        {person.Subject}
                      </td>
                      {/* <td
                      className={classNames(
                        personIdx !== props.data.length - 1
                          ? "border-b border-gray-200"
                          : "",
                        "whitespace-nowrap px-3 py-4 text-sm text-gray-500  lg:table-cell"
                      )}
                    >
                      {person.TemplateName}
                    </td> */}
                      <td
                        className={classNames(
                          "whitespace-nowrap px-3 py-4 text-sm text-gray-500 relative"
                        )}
                      >
                        <p className="truncate w-36 cursor-default">
                          {person.DynamicField}
                        </p>
                      </td>
                      {/* <td
                      className={classNames(
                        personIdx !== props.data.length - 1
                          ? "border-b border-gray-200"
                          : "",
                        "whitespace-nowrap px-3 py-4 text-sm text-gray-500"
                      )}
                    >
                      {person.CreatedAt}
                    </td> */}
                      <td
                        className={classNames(
                          personIdx !== props.data.length - 1
                            ? "border-b border-gray-200"
                            : "",
                          "whitespace-nowrap px-3 py-4 text-sm text-gray-500"
                        )}
                      >
                        {dateHandler(person.CreatedAt)}
                      </td>
                      <td
                        className={classNames(
                          personIdx !== props.data.length - 1
                            ? "border-b border-gray-200"
                            : "",
                          "relative whitespace-nowrap py-4 pr-4 pl-3 text-right text-sm font-medium sm:pr-6 lg:pr-8"
                        )}
                      >
                        <div
                          className="text-indigo-600 hover:text-indigo-900 cursor-pointer"
                          onClick={() =>
                            navigate("/edittemplate/" + person.EmailTemplateID)
                          }
                        >
                          Update
                          <span className="sr-only">, {person.FirstName}</span>
                        </div>
                      </td>
                      <td
                        className={classNames(
                          personIdx !== props.data.length - 1
                            ? "border-b border-gray-200"
                            : "",
                          "relative whitespace-nowrap py-4 pr-4 pl-3 text-right text-sm font-medium sm:pr-6 lg:pr-8"
                        )}
                      >
                        <span
                          className="text-red-600 hover:text-red-900 cursor-pointer"
                          onClick={() => {
                            props.deleteClicked(person.EmailTemplateID);
                          }}
                        >
                          {/* <RiDeleteBin6Line /> */}
                          Delete
                        </span>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={7}>
                      <DataNotFound />
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        );
        break;
    }
  };

  return <div>{tableType(props.tableType)}</div>;
};

export default Table;
