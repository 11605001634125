import React from 'react';
import Button from './Button';
import Input from './Input';

const DatePicker = (props) =>{

    return (
        <div className="bg-white py-4 flex flex-col justify-center sm:rounded-lg ">
          <div className="flex flex-col items-center md:flex-row ">
            <div className="flex flex-col items-start sm:items-center sm:flex-row">
              <div className="mr-4 lg:mr-8">
                <Input
                  type="date"
                  dateLabel="startDate"
                  label="From"
                  value={props.startDate}
                  onChange={props.StartOnChange}
                  min={props.min}
                />
              </div>
              <div className="mr-4 mt-2 sm:mt-0">
                <Input
                  type="date"
                  dateLabel="endDate"
                  label="To"
                  value={props.endDate}
                  onChange={props.EndOnChange}
                  min={props.min}
                />
              </div>
              <div className="mt-3 ml-14 sm:ml-0 m-auto sm:mt-0">
                <Button
                  type={"dateButton"}
                  buttonText={"Search"}
                  onClick={props.onClickBtn}
                />
              </div>
            </div>
          </div>
        </div>
      );
}

export default DatePicker;