import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/20/solid";

const Pagination = (props) => {

  const setPage = (value) => {
    let intValue = parseInt(value);
    props.hitPage(intValue);
  };

  const getTillData = () =>{
    return ((20*(props.pageNum-1))+props.dataLength);
  }

  return (
    <div className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6">
      <div className="flex flex-1 justify-between sm:hidden">
        <span className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50">
          Previous
        </span>
        <span className="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50">
          Next
        </span>
      </div>
      <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
        <div>
          {
            props.dataLength > 0 ?
          <p className="text-sm text-gray-700">
            Showing <span className="font-medium"> {props.pageNum === 1 ? 1 : (20 * (props.pageNum-1)+1 )} </span> to
            {/* <span className="font-medium"> {props.dataLength} </span> of */}
            <span className="font-medium"> {props.pageNum === 1 ? props.dataLength : getTillData() } </span> of
            <span className="font-medium"> {props.totalCount ? props.totalCount : ""} {props.count ? props.count : ""} </span> results
          </p>
          :""
}
        </div>
        <div>
          { props.maxPages > 1 &&
          <nav
            className="isolate inline-flex -space-x-px rounded-md shadow-sm"
            aria-label="Pagination"
          >
            <span className="relative inline-flex items-center rounded-l-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20"
            onClick={()=>{props.pageNum > 1 && setPage(props.pageNum - 1)}}
            >
              <span className="sr-only">Previous</span>
              <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
            </span>

            {props.maxPages >= 1 && (
              <span
                className={`relative inline-flex items-center border px-4 py-2 text-sm font-medium focus:z-30 cursor-pointer ${props.pageNum + 0 <= props.maxPages - 5 ? "border-indigo-500 text-indigo-600 bg-indigo-50 z-20" : (props.maxPages <= 6 ? (props.pageNum === 1 ? "border-indigo-500 text-indigo-600 bg-indigo-50 z-20" : "" ) : (props.pageNum === props.maxPages - 5 ? "border-indigo-500 text-indigo-600 bg-indigo-50 z-20" : ""))}`}
                onClick={(e) => {
                  setPage(e.target.innerText);
                }}
              >
                {props.pageNum + 0 <= props.maxPages - 5 ? props.pageNum + 0 : (props.maxPages - 5 < 1 ? 1 : props.maxPages - 5) }
              </span>
            )}

            {props.maxPages >= 2 && (
              <span
              className={`relative inline-flex items-center border px-4 py-2 text-sm font-medium focus:z-20 cursor-pointer ${props.pageNum + 1 <= props.maxPages - 4 ? "" : (props.maxPages <= 6 ? (props.pageNum === 2 ? "border-indigo-500 text-indigo-600 bg-indigo-50 z-20" : "" ) : (props.pageNum === props.maxPages - 4 ? "border-indigo-500 text-indigo-600 bg-indigo-50 z-20" : ""))}`}
                onClick={(e) => {
                  setPage(e.target.innerText);
                }}
              >
                {/* {props.pageNum + 1} */}
                {props.pageNum + 1 <= props.maxPages - 4 ? props.pageNum + 1 :(props.maxPages - 4 < 2 ? 2 : props.maxPages - 4)}
              </span>
            )}

            {props.maxPages >= 3 && (
              <span
              className={`relative inline-flex items-center border px-4 py-2 text-sm font-medium  focus:z-20 cursor-pointer ${props.pageNum + 2 <= props.maxPages - 3 ? "" : (props.maxPages <= 6 ? (props.pageNum === 3 ? "border-indigo-500 text-indigo-600 bg-indigo-50 z-20" : "" ) : (props.pageNum === props.maxPages - 3 ? "border-indigo-500 text-indigo-600 bg-indigo-50 z-20" : ""))}`}
                onClick={(e) => {
                  setPage(e.target.innerText);
                }}
              >
              {/* {props.pageNum + 2} */}
              {props.pageNum + 2 <= props.maxPages - 3 ? props.pageNum + 2 : (props.maxPages - 3 < 3 ? 3 : props.maxPages - 3)}
              </span>
            )}

            {props.maxPages >= 4 && (
              <span
              className={`relative inline-flex items-center border px-4 py-2 text-sm font-medium  focus:z-20 cursor-pointer ${props.pageNum + 3 <= props.maxPages - 2 ? "" : (props.maxPages <= 6 ? (props.pageNum === 4 ? "border-indigo-500 text-indigo-600 bg-indigo-50 z-20" : "" ) : (props.pageNum === props.maxPages - 2 ? "border-indigo-500 text-indigo-600 bg-indigo-50 z-20" : ""))}`}
                onClick={(e) => {
                  setPage(e.target.innerText);
                }}
              >
                {/* {props.pageNum + 3} */}
                {props.pageNum + 3 <= props.maxPages - 2 ? props.pageNum + 3 : (props.maxPages - 2 < 4 ? 4 : props.maxPages - 2)}
              </span>
            )}

            {props.maxPages >= 5 && (
              <span
              className={`relative inline-flex items-center border px-4 py-2 text-sm font-medium  focus:z-20 cursor-pointer ${props.pageNum + 4 <= props.maxPages - 1 ? "" : (props.maxPages <= 6 ? (props.pageNum === 5 ? "border-indigo-500 text-indigo-600 bg-indigo-50 z-20" : "" ) : (props.pageNum === props.maxPages - 1 ? "border-indigo-500 text-indigo-600 bg-indigo-50 z-20" : ""))}`}
                onClick={(e) => {
                  setPage(e.target.innerText);
                }}
              >
                {/* {props.pageNum + 4} */}
                {props.pageNum + 4 <= props.maxPages - 1 ? props.pageNum + 4 :(props.maxPages - 1 < 5 ? 5 : props.maxPages - 1)}
              </span>
            )}

            {props.maxPages >= 6 && (
              <span
              className={`relative z-10 inline-flex items-center border px-4 py-2 text-sm font-medium  focus:z-20 cursor-pointer ${props.pageNum + 5 <= props.maxPages - 0 ? "" : (props.maxPages <= 6 ? (props.pageNum === 6 ? "border-indigo-500 text-indigo-600 bg-indigo-50 z-20" : "" ) : (props.pageNum === props.maxPages - 0 ? "border-indigo-500 text-indigo-600 bg-indigo-50 z-20" : ""))}`}
                onClick={(e) => {
                  setPage(e.target.innerText);
                }}
              >
                {/* {props.pageNum + 5} */}
                {props.pageNum + 5 <= props.maxPages - 0 ? props.pageNum + 5 :(props.maxPages - 0 < 6  ? 6 : props.maxPages - 0)}
              </span>
            )}
            <span className="relative inline-flex items-center rounded-r-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20"
            onClick={()=>{props.pageNum < props.maxPages && setPage(props.pageNum + 1)}}
            >
              <span className="sr-only">Next</span>
              <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
            </span>
          </nav>
}
        </div>
        
      </div>
    </div>
  );
};

export default Pagination;
