import React, { useMemo, useState, useRef, useEffect } from "react";
import Button from "../Components/TailwindComponent/Button";
import DashboardHeader from "../Components/TailwindComponent/DashboardHeader";
import Input from "../Components/TailwindComponent/Input";
import SidebarWrapper from "../Components/TailwindComponent/SidebarWrapper";
import SuccessMessage from "../Components/TailwindComponent/SuccessMessage";
import TextEditorTiny from "../Components/TailwindComponent/TextEditorTiny";

const SendBulkEmailScreen = () => {
  const [templateId, setTemplateId] = useState("");
  const [csvValue, setCsvValue] = useState("");
  const [intData, setIntData] = useState("");
  const [schDate, setSchDate] = useState("");
  const [schTime, setSchTime] = useState("");
  const [file, setFile] = useState("");
  const [checkBoxState, setCheckBoxState] = useState(false);
  const [radioValue, setRadioValue] = useState("uploadContact");
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [schLoading, setSchLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [apiFailled, setApiFailled] = useState(false);
  const [alertMessage, setAlertMessage] = useState("Status Updated");
  const [errors, setErrors] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const [minimumDate, setMinimumDate] = useState("");
  const [selectEmail, setSelectEmail] = useState([])
  const [emailSent, setEmailSent] = useState("")
  const [dynamic, setDynamic] = useState("")
  const [iframeCustomCode, setIframeCustomCode] = useState("")

  const csvUpload = useRef(null);

  const date = new Date();

  useEffect(() => {
    let fakeDate = `${date.getFullYear()}-${date.getMonth() + 1
      }-${date.getDate()}`;
    let fakeArray = fakeDate.split("-");
    if (fakeArray[1].length === 1) {
      fakeArray[1] = `0${fakeArray[1]}`;
    }
    if (fakeArray[2].length === 1) {
      fakeArray[2] = `0${fakeArray[2]}`;
    }
    let freshArray = fakeArray.join("-");
    setMinimumDate(freshArray);
  }, []);

  useEffect(() => {
    getListTemplate();
    GetValidEmail()
  }, []);

  const getTemplateData = async (e) => {
    let fetchedData = await fetch(
      "https://bulk.sasone.in/emailapi/v1/bulkemail/GetIDEmailTemplateAPI",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token 8cb23c1e5efb27dbadefabe601226a4264f59daa",
        },
        body: JSON.stringify({
          EmailTemplateID: e,
        }),
      }
    );

    let parseData = await fetchedData.json();

    if (parseData.status === "Success" && parseData.status_code === 200) {
      // console.log(parseData.data);
      setIframeCustomCode(parseData.data.TemplateBody)
      setDynamic(parseData.data.DynamicField);
    }
  };


  const getListTemplate = async () => {
    let fetchedData = await fetch(
      "https://bulk.sasone.in/emailapi/v1/bulkemail/GetEmailTemplate",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token 8cb23c1e5efb27dbadefabe601226a4264f59daa",
        },
      }
    );

    let parsedData = await fetchedData.json();

    if (parsedData.status === "Success" && parsedData.status_code === 200) {

      setOptions(parsedData.data);
      // setTemplateId(parsedData.data[0].EmailTemplateID);
    }
  };
  const GetValidEmail = async () => {
    let fetchedData = await fetch(
      "https://bulk.sasone.in/emailapi/v1/bulkemail/ApprovedEmail",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token 8cb23c1e5efb27dbadefabe601226a4264f59daa",
        },
      }
    );

    let parsedData = await fetchedData.json();

    if (parsedData.status === "Success" && parsedData.status_code === 200) {
      setSelectEmail(parsedData.data);
      // setTemplateId(parsedData.data[0].EmailTemplateID);
    }
  };

  useMemo(() => {
    setTimeout(() => {
      setShow(false);
    }, 5000);
  }, [show]);

  const sendEmail = async () => {
    setLoading(true);

    if (checkVacant()) {
      // Value insertion when Upload Contact is selected
      if (radioValue === "uploadContact") {
        let headersList = {
          Authorization: "Token 8cb23c1e5efb27dbadefabe601226a4264f59daa",
        };

        let bodyContent = new FormData();
        bodyContent.append("EmailTemplateID", templateId);
        bodyContent.append("EmailFile", file);
        bodyContent.append("EmailFrom", emailSent);


        let fetchedData = await fetch(
          "https://bulk.sasone.in/emailapi/v1/bulkemail/SendEmailAPI",
          {
            method: "POST",
            body: bodyContent,
            headers: headersList,
          }
        );

        let parseData = await fetchedData.json();

        if (parseData.status_code === 200 && parseData.status === "Success") {
          setAlertMessage("Email Sent Successfully!");
          setApiFailled(false);
          setShow(true);
          setTemplateId("");
          setEmailSent("")
          setFile("");
          setCsvValue("");
          csvUpload.current.value = "";
        } else {
          setAlertMessage(parseData.Massage);
          setApiFailled(true);
          setShow(true);
        }
      }

      // Value insertion when Internate Data is selected
      if (radioValue === "internateData") {
        let headersList = {
          Authorization: "Token 8cb23c1e5efb27dbadefabe601226a4264f59daa",
        };

        let bodyContent = new FormData();
        bodyContent.append("EmailTemplateID", templateId);
        bodyContent.append("Internate", intData);

        let fetchedData = await fetch(
          "https://bulk.sasone.in/emailapi/v1/bulkemail/SendEmailAPI",
          {
            method: "POST",
            body: bodyContent,
            headers: headersList,
          }
        );

        let parseData = await fetchedData.json();

        if (parseData.status_code === 200 && parseData.status === "Success") {
          setAlertMessage("Data Sent Successfully!");
          setApiFailled(false);
          setShow(true);
        } else {
          setAlertMessage("Action Failed!");
          setApiFailled(true);
          setShow(true);
        }
      }
    }

    setLoading(false);
  };

  const scheduleEmail = async () => {
    setSchLoading(true);

    if (checkVacant()) {
      if (radioValue === "uploadContact") {
        let headersList = {
          Authorization: "Token 8cb23c1e5efb27dbadefabe601226a4264f59daa",
        };

        let bodyContent = new FormData();
        bodyContent.append("EmailTemplateID", templateId);
        bodyContent.append("EmailSource", file);
        bodyContent.append("EmailFrom", emailSent);
        bodyContent.append("ScheduledDate", schDate);
        bodyContent.append("ScheduledTime", schTime);

        let fetchedData = await fetch(
          "https://bulk.sasone.in/emailapi/v1/bulkemail/EmailSchedulerapi",
          {
            method: "POST",
            body: bodyContent,
            headers: headersList,
          }
        );

        let parseData = await fetchedData.json();

        if (parseData.status_code === 200 && parseData.status === "Success") {
          setAlertMessage("Email Scheduled Successfully!");
          setApiFailled(false);
          setShow(true);
          setTemplateId("");
          setEmailSent("")
          setFile("");
          setCsvValue("");
          setSchDate("");
          setSchTime("");
          csvUpload.current.value = "";
        } else if (
          parseData.status_code === 200 &&
          parseData.status === "fail"
        ) {
          setAlertMessage(
            "Action Failed! Schedule time should be greater than 1 hour from current time"
          );
          setApiFailled(true);
          setShow(true);

        } else if (
          parseData.status_code === "400" &&
          parseData.status === "Fail"
        ) {
          setAlertMessage(parseData.Massage);
          setApiFailled(true);
          setShow(true);
        }
      }

      // Value insertion when Internate Data is selected
      if (radioValue === "internateData") {
        let headersList = {
          Authorization: "Token 8cb23c1e5efb27dbadefabe601226a4264f59daa",
        };

        let bodyContent = new FormData();
        bodyContent.append("EmailTemplateID", templateId);
        bodyContent.append("Internate", intData);
        bodyContent.append("EmailFrom", "rajankumar@onecorp.co.in");
        bodyContent.append("ScheduledDate", schDate);
        bodyContent.append("ScheduledTime", schTime);

        let fetchedData = await fetch(
          "https://bulk.sasone.in/emailapi/v1/bulkemail/EmailSchedulerapi",
          {
            method: "POST",
            body: bodyContent,
            headers: headersList,
          }
        );

        let parseData = await fetchedData.json();

        if (parseData.status_code === 200 && parseData.status === "Success") {
          setAlertMessage("Email Schedule Successfully!");
          setApiFailled(false);
          setShow(true);
        } else {
          setAlertMessage("Action Failed!");
          setApiFailled(true);
          setShow(true);
        }
      }
    }

    setSchLoading(false);
  };

  const checkVacant = () => {
    let isValid = true;
    let error = {};

    if (!templateId || templateId === "") {
      error.templateId = "Please select a template";
      isValid = false;
    }
    if (!emailSent || emailSent === "") {
      error.emailSent = "Please select a Email";
      isValid = false;
    }


    if (radioValue === "uploadContact") {
      if (!csvValue || csvValue === "") {
        error.csvValue = "Please select a CSV or Excel file";
        isValid = false;
      }
    }

    if (radioValue === "internateData") {
      if (!intData || intData === "") {
        error.intData = "Please select a data";
        isValid = false;
      }
    }

    if (checkBoxState) {
      if (!schDate || schDate === "") {
        error.schDate = "Please Select a schedule date";
        isValid = false;
      }

      if (!schTime || schTime === "") {
        error.schTime = "Please select a schedule time";
        isValid = false;
      }
    }

    setErrors(error);
    return isValid;
  };

  const openCsvUploader = () => {
    csvUpload.current.click();
  };

  const fileUploaded = (value) => {
    if (
      value.type ===
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
      value.type === "text/csv"
    ) {
      setFile(value);
      setCsvValue(value.name);
    } else {
      let error = {};
      error.csvValue = "Please select a CSV or Excel file";
      setFile("");
      setCsvValue("");
      setErrors(error);
    }
  };

  const checkHandler = (value) => {
    setCheckBoxState(value.checked);
  };

  const checkRadio = (node) => {
    setRadioValue(node.value);
  };

  const internateData = [
    {
      name: "Wade Cooper",
      value: "wade",
    },
    {
      name: "Arlene Mccoy",
      value: "arlene",
    },
  ];


  return (
    <div>
      <SidebarWrapper>
        <SuccessMessage
          show={show}
          apiFailled={apiFailled}
          message={alertMessage}
          onClose={() => {
            setShow(false);
          }}
        />
        <DashboardHeader Heading={"Send Bulk Email"} />

        {/* <SimpleNavbar type="bulkEmail" /> */}

        <div className="w-full mx-auto">
          <div className="flex flex-col md:flex-row justify-center px-8 md:px-0 md:items-center my-14">
            <div className="text-md font-semibold whitespace-nowrap mr-2 flex items-start">
              Select Template
            </div>
            <div className="w-full md:w-64 lg:w-1/2 ml-0 md:ml-10 lg:ml-20 relative">
              <Input
                type="selectSimple"
                options={options}
                onChange={(e) => {
                  setTemplateId(e);
                  getTemplateData(e)
                  errors.templateId = "";
                }}
                value={templateId}
              // selValue={options ? options[0].EmailTemplateID : ""}
              />
              <p className="text-red-500 text-sm mt-2 absolute top-full">
                {errors.templateId}
              </p>
              {templateId && <div className=" text-sm mt-2 absolute top-full"><span className="text-gray-800 font-medium">Dynamic field:</span>{" "}{dynamic}</div>}
            </div>

          </div>
         {templateId&& <div className="flex flex-col md:flex-row justify-center px-8 md:px-0 md:items-center my-14">
            <div className="sr-only text-md font-semibold whitespace-nowrap mr-2 flex items-start">
              Template preview
            </div>
            <div className="w-full md:w-64 lg:w-1/2 ml-0 md:ml-40 lg:ml-48 relative">
              <TextEditorTiny value={iframeCustomCode} onChangeContent={setIframeCustomCode}/>
              {/* <p className="text-red-500 text-sm mt-2 absolute top-full">
                {errors.emailSent}
              </p> */}
            </div>
          </div>}
          <div className="flex flex-col md:flex-row justify-center px-8 md:px-0 md:items-center my-14">
            <div className="text-md font-semibold whitespace-nowrap mr-2 flex items-start">
              Select Email
            </div>
            <div className="w-full md:w-64 lg:w-1/2 ml-0 md:ml-16 lg:ml-24 relative">
              <Input
                type="selectSimple"
                options={selectEmail}
                onChange={(e) => {
                  setEmailSent(e);
                  errors.emailSent = "";
                }}
                value={emailSent}
              // selValue={options ? options[0].EmailTemplateID : ""}
              />
              <p className="text-red-500 text-sm mt-2 absolute top-full">
                {errors.emailSent}
              </p>
            </div>
          </div>

          <div className="flex flex-col md:flex-row justify-center px-8 md:px-0 md:items-center my-14">
            <div className="text-md font-semibold whitespace-nowrap relative">
              Upload Contacts
              <div className="absolute left-36 md:-left-10 bottom-0.5">
                <Input
                  type="RadioGroup"
                  notificationMethods={[{ id: "uploadContact", title: "" }]}
                  radioName="bulkEmail"
                  onChange={checkRadio}
                  radioValue={radioValue}
                />
              </div>
            </div>
            <div className="w-full md:w-64 lg:w-1/2 flex flex-col relative ml-0 md:ml-10 lg:ml-20">
              <Input
                type="InputWithButton"
                placeholder="Event Contacts.CSV"
                btnText="Upload"
                inputType="text"
                value={csvValue}
                onClickBtn={openCsvUploader}
                disable={radioValue === "uploadContact" ? false : true}
              />
              <p className="text-xs text-gray-500 absolute top-full mt-2">
                <b>Note :</b> Keep column headings in UPPERCASE
              </p>
              <p className="text-red-500 text-sm mt-6 absolute top-full">
                {errors.csvValue}
              </p>
              <input
                type="file"
                className="w-0 h-0"
                onChange={(e) => {
                  fileUploaded(e.target.files[0]);
                  errors.csvValue = "";
                }}
                // value={}
                ref={csvUpload}
              />
            </div>
          </div>

          <div className="flex flex-col md:flex-row justify-center px-8 md:px-0 md:items-center my-14">
            <div className="text-md font-semibold whitespace-nowrap flex relative">
              Internal Data
              <p className="md:absolute -bottom-4 text-xs text-gray-500 mt-1 md:mt-0 ml-2 md:ml-0">
                (From Data)
              </p>
              <div className="absolute left-48 md:-left-11 bottom-0.5">
                <Input
                  type="RadioGroup"
                  notificationMethods={[{ id: "internateData", title: "" }]}
                  radioName="bulkEmail"
                  onChange={checkRadio}
                  radioValue={radioValue}
                />
              </div>
            </div>
            <div className="w-full md:w-64 lg:w-1/2 flex flex-col relative ml-0 md:ml-14 lg:ml-24">
              <Input
                type="selectDoubleArrow"
                internateData={
                  radioValue === "internateData" ? internateData : []
                }
                onClick={(e) => {
                  setIntData(e);
                  errors.intData = "";
                }}
                disable={radioValue === "internateData" ? false : true}
              />
              <p className="text-red-500 text-sm mt-2 absolute top-full">
                {errors.intData}
              </p>
            </div>
          </div>

          <div className="flex flex-col md:flex-row justify-center px-8 md:px-0 md:items-center my-14">
            <div className="text-md font-semibold whitespace-nowrap relative mb-8 md:mb-0">
              Schedule Date Time
              <div className="absolute left-40 md:-left-10 bottom-0.5">
                <Input
                  type="checkBox"
                  inputType="time"
                  onCheckChange={(e) => {
                    checkHandler(e.target);
                  }}
                  data={[
                    {
                      value: "temp1",
                      title: "Template 1",
                      label: "",
                      description: "",
                    },
                  ]}
                />
              </div>
            </div>
            <div className="w-full md:w-64 lg:w-1/2 flex flex-col relative ml-0 md:ml-4 lg:ml-14">
              <Input
                type="InputDateOrTime"
                inputType="date"
                label={"Date"}
                onChange={(e) => {
                  setSchDate(e);
                  errors.schDate = "";
                }}
                disable={!checkBoxState}
                min={minimumDate}
              />
              <p className="text-red-500 text-sm mt-2 absolute top-full">
                {errors.schDate}
              </p>
            </div>
          </div>

          <div className="flex flex-col md:flex-row justify-center px-8 md:px-0 md:items-center my-14">
            <div className="w-full md:w-64 lg:w-1/2 flex flex-col relative ml-0 md:ml-40 lg:ml-52">
              <Input
                type="InputDateOrTime"
                inputType="time"
                label={"Time"}
                onChange={(e) => {
                  setSchTime(e);
                }}
                disable={!checkBoxState}
              />
              <p className="text-red-500 text-sm mt-2 absolute top-full">
                {errors.schTime}
              </p>
              <p className="text-red-500 text-sm mt-2 absolute top-full">
                {errorMessage}
              </p>
            </div>
          </div>

          <div className="flex justify-center px-8 md:px-0 md:items-center my-14">
            <div className="flex justify-between w-full md:w-64 lg:w-1/2 md:ml-40 lg:ml-52">
              <div className="">
                <Button
                  type="blueFilled"
                  buttonText="Send Now"
                  check={checkBoxState ? true : false}
                  loading={loading}
                  onClick={sendEmail}
                />
              </div>
              <div className="">
                <Button
                  type="blueFilled"
                  buttonText="Schedule"
                  check={checkBoxState ? false : true}
                  onClick={scheduleEmail}
                  loading={schLoading}
                />
              </div>
            </div>
          </div>
        </div>
      </SidebarWrapper>
    </div>
  );
};

export default SendBulkEmailScreen;
