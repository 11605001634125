import React,{useEffect} from 'react'
import './App.css';
import {BrowserRouter as Router,Routes,Route, useNavigate, useLocation} from "react-router-dom";
import Dashboard from './Screens/Dashboard';
import Templatesdata from './Screens/Templatesdata';
import TemplateEditdata from './Screens/TemplateEditData';
import SendBulkEmailScreen from './Screens/SendBulkEmailScreen';
import ScheduledMailScreen from './Screens/ScheduledMailScreen';
import MailHistoryScreen from './Screens/MailHistoryScreen';
import TemplatesList from './Screens/TemplatesList';
import SignInRegistration from './Components/TailwindComponent/SignInRegistration';

const App = () => {


  
  return (
     <>
        <Router>
        <PublicRedirect/>
          <Routes>
            <Route path="/" element={<SignInRegistration /> } />
            <Route path="/dashboard" element={< Dashboard/>} />
            <Route path="/template" element={< Templatesdata/>} />
            <Route path="/edittemplate/:id" element={< TemplateEditdata/>} />
            <Route path="/bulkemail" element={ <SendBulkEmailScreen /> } />
            <Route path="/scheduledmail" element={< ScheduledMailScreen/>} />
            <Route path="/mailhistory" element={< MailHistoryScreen/>} />
            <Route path="/templatelist" element={< TemplatesList/>} />


          </Routes>
       </Router>

      </>
  )

}
const PublicRedirect = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  
  useEffect(() => {
      if (!localStorage.getItem("auth_token")) {
         navigate("/")
      }
  }, [pathname]);
  return <></>;
};

export default App;