import React from "react";
import { useState } from "react";
import { useEffect, useMemo } from "react";
import DashboardHeader from "../Components/TailwindComponent/DashboardHeader";
import SidebarWrapper from "../Components/TailwindComponent/SidebarWrapper";
import Table from "../Components/TailwindComponent/Table";
import Pagination from "../Components/TailwindComponent/Pagination";
import Input from "../Components/TailwindComponent/Input";
import DatePicker from "../Components/TailwindComponent/DatePicker";

const MailHistoryScreen = () => {
  const [data, setData] = useState([]);
  const [maxPage, setMaxPage] = useState(1);
  const [page, setPage] = useState(1);
  const [searchPage, setSearchPage] = useState(1);
  const [totalCount, setTotalCount] = useState(1);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [startChange, setStartChange] = useState("");
  const [endChange, setEndChange] = useState("");
  const [searchTerm, setSearchTerm] = useState("");

  // useEffect(() => {
  //   defaultDate();

  // }, []);
  useEffect(() => {
    var date = new Date();
    date.setDate(date.getDate() - 30);

    let startDateformat =
      date.getDate().toString().length === 1
        ? "0" + date.getDate()
        : date.getDate();

    const monthFormat =
      date.getMonth() + 1 > 9
        ? date.getMonth() + 1
        : "0" + (date.getMonth() + 1);

    var finalDate =
      date.getFullYear() + "-" + monthFormat + "-" + startDateformat;
    setStartDate(finalDate);

    var todayDate = new Date();
    todayDate.setDate(todayDate.getDate());

    let endDateformat =
      todayDate.getDate().toString().length === 1
        ? "0" + todayDate.getDate()
        : todayDate.getDate();
    const todaymonthFormat =
      todayDate.getMonth() + 1 > 9
        ? todayDate.getMonth() + 1
        : "0" + (todayDate.getMonth() + 1);

    var finalToday =
      todayDate.getFullYear() + "-" + todaymonthFormat + "-" + endDateformat;
    setEndDate(finalToday);
    getEmailList(finalDate, finalToday, page);
  }, []);

  const getEmailList = async (end, today) => {
    let fetchedData = await fetch(
      "https://bulk.sasone.in/emailapi/v1/bulkemail/SearchingApiOfMailHistory",
      {
        method: "POST",
        headers: {
          Authorization: "Token 8cb23c1e5efb27dbadefabe601226a4264f59daa",
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          Searching: searchTerm,
          page: page,
          StartDate: end ? end : startDate,
          EndDate: today ? today : endDate
        })
      }
    );

    let parsedData = await fetchedData.json();

    if (parsedData.status_code === 200 && parsedData.status === "Success") {
      console.log(parsedData.data);
      setData(parsedData.data);
      setTotalCount(parsedData.totalCount);
      let data = parsedData.totalCount / 20;
      setMaxPage(Math.ceil(data));
    } else {
      setData([]);
      setMaxPage(1);
    }
  };

  // const SearchTemplateList = async () => {
  //     let fetchedData = await fetch(
  //       "https://bulk.sasone.in/emailapi/v1/bulkemail/SearchingApiOfMailHistory",
  //       {
  //         method: "POST",
  //         headers: {
  //           Authorization: "Token 8cb23c1e5efb27dbadefabe601226a4264f59daa",
  //           "Content-Type": "application/json",
  //         },
  //         body: JSON.stringify({
  //           Searching: searchTerm,
  //           page: searchPage,
  //           StartDate: startDate,
  //           EndDate: endDate
  //         }),
  //       }
  //     );

  //     let parsedData = await fetchedData.json();

  //     if (parsedData.status_code === 200 && parsedData.status === "Success") {
  //       setData(parsedData.data);
  //       setTotalCount(parsedData.totalCount);
  //       let data = parsedData.totalCount / 20;
  //       setMaxPage(Math.ceil(data));
  //     }else{
  //       setData([]);
  //       setMaxPage(1);
  //     }
  //     setPage(1);
  // };

  useMemo(() => {
    getEmailList();
  }, [page, searchTerm === ""]);

  const pageHandler = (value) => {
    setPage(value);
  };

  // const defaultDate = () => {
  //   const date = new Date();

  //   let beginDate = `${date.getFullYear()}-${date.getMonth()}-${date.getDate()}`;
  //   let splitOne = beginDate.split("-");

  //   if (splitOne[1].length === 1) {
  //     splitOne[1] = `0${splitOne[1]}`;
  //   }

  //   if (splitOne[2].length === 1) {
  //     splitOne[2] = `0${splitOne[2]}`;
  //   }

  //   let goodBegin = splitOne.join("-");
  //   setStartDate(goodBegin);
  //   setStartChange(goodBegin);

  //   let finishDate = `${date.getFullYear()}-${
  //     date.getMonth() + 1
  //   }-${date.getDate()}`;
  //   let splitTwo = finishDate.split("-");

  //   if (splitTwo[1].length === 1) {
  //     splitTwo[1] = `0${splitTwo[1]}`;
  //   }

  //   if (splitTwo[2].length === 1) {
  //     splitTwo[2] = `0${splitTwo[2]}`;
  //   }

  //   let goodFinish = splitTwo.join("-");
  //   setEndDate(goodFinish);
  //   setEndChange(goodFinish);
  //   getEmailList(goodBegin,goodFinish);
  // };


  return (
    <div>
      <SidebarWrapper>
        <DashboardHeader Heading={"Mail History"} />
        <div className="my-3 flex flex-col xl:flex-row items-start sm:items-center justify-between px-4 sm:px-0">
          <DatePicker
            searchTerm={searchTerm}
            endDate={endDate}
            startDate={startDate}
            StartOnChange={(e) => {
              setStartDate(e.target.value);
            }}
            EndOnChange={(e) => {
              setEndDate(e.target.value);
            }}
            onClickBtn={() => { page === 1 ? getEmailList() : setPage(1) }}
          />

          <Input
            type="SearchBox"
            onChange={setSearchTerm}
            value={searchTerm}
            onClick={() => { page === 1 ? getEmailList() : setPage(1) }}
          />
        </div>

        <Table people={data} tableType={"MailHistory"} />
        <Pagination
          maxPages={maxPage}
          hitPage={pageHandler}
          pageNum={page}
          dataLength={data === null ? 0 : data.length}
          totalCount={totalCount}
        />
      </SidebarWrapper>
    </div>
  );
};

export default MailHistoryScreen;
