import React, { useEffect, useRef, useState, useMemo } from "react";
import Button from "../Components/TailwindComponent/Button";
import DashboardHeader from "../Components/TailwindComponent/DashboardHeader";
import Input from "../Components/TailwindComponent/Input";
import SimpleNavbar from "../Components/TailwindComponent/Navbar";
import SidebarWrapper from "../Components/TailwindComponent/SidebarWrapper";
import { useNavigate, useParams } from "react-router-dom";
import SuccessMessage from "../Components/TailwindComponent/SuccessMessage";

const Templatesdata = () => {
  let { id } = useParams();
  let navigate = useNavigate();

  const [name, setName] = useState("");
  const [file, setFile] = useState("");
  const [subject, setSubject] = useState("");
  const [htmlFileName, setHtmlFileName] = useState("");
  const [previewFile, setPreviewFile] = useState("");
  const [iframeCustomCode, setIframeCustomCode] = useState("");
  const [dynamic, setDynamic] = useState("");
  const [show, setShow] = useState(false);
  const [apiFailled, setApiFailled] = useState(false);
  const [errors, setErrors] = useState({});
  const [alertMessage, setAlertMessage] = useState("Status Updated");

  const htmlUpload = useRef(null);

  useEffect(() => {
    getTemplateData();
  }, []);

  useMemo(() => {
    setTimeout(() => {
      setShow(false);
    }, 5000);
  }, [show]);

  const getTemplateData = async () => {
    let fetchedData = await fetch(
      "https://bulk.sasone.in/emailapi/v1/bulkemail/GetIDEmailTemplateAPI",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token 8cb23c1e5efb27dbadefabe601226a4264f59daa",
        },
        body: JSON.stringify({
          EmailTemplateID: id,
        }),
      }
    );

    let parseData = await fetchedData.json();

    if (parseData.status === "Success" && parseData.status_code === 200) {
      setName(parseData.data.TemplateName);
      setSubject(parseData.data.Subject);
      setIframeCustomCode(parseData.data.TemplateBody);
      setDynamic(parseData.data.DynamicField);
    }
  };

  const editTemplate = async () => {
    if (checkVacant()) {
    // if (false) {
      // let mainDynamic = [];


      let bodyContent = new FormData();
      bodyContent.append("EmailTemplateID", id);
      bodyContent.append("Subject", subject);
      bodyContent.append("TemplateName", name);
      bodyContent.append("TemplateBody", iframeCustomCode);
      bodyContent.append("DynamicField", `${dynamic}`);
      bodyContent.append("TemplateBodyFile", file);

      let response = await fetch(
        "https://bulk.sasone.in/emailapi/v1/bulkemail/EditTemplateAPI",
        {
          method: "POST",
          headers: {
            Authorization: "Token 8cb23c1e5efb27dbadefabe601226a4264f59daa",
          },
          body: bodyContent,
        }
      );

      let parseData = await response.json();

      if (parseData.status_code === 200 && parseData.status === "Success") {
        setAlertMessage("Template Edited Successfully!");
        setApiFailled(false);
        setShow(true);
        getTemplateData();
        setTimeout(() => {
          navigate("/templatelist");
        }, 2000);
      } else {
        setAlertMessage("Status Failed!");
        setApiFailled(true);
        setShow(true);
      }
    }
  };

  const fileTaker = () => {
    htmlUpload.current.click();
  };

  const FileRemover = () => {
    setHtmlFileName("");
    setFile("");
    setPreviewFile("");
    htmlUpload.current.value = "";
  };

  const setCode = (value) => {
    setIframeCustomCode(value);
  };

  const fileUploaded = (value, address) => {
    if (value.type === "text/html") {
      setHtmlFileName(value.name);
      setFile(value);
      setPreviewFile(address);
      setIframeCustomCode("");
    } else {
      htmlUpload.current.value = "";
      setHtmlFileName("");
      setFile("");
      setAlertMessage("This is not a Html Template");
      setApiFailled(true);
      setShow(true);
    }
  };

  const checkVacant = () => {
    let isValid = true;
    let error = {};

    if (!subject || subject === "") {
      error.subject = "Please enter email subject";
      isValid = false;
    }

    if (!name || name === "") {
      error.name = "Please enter template name";
      isValid = false;
    }

    // if (!dynamic || dynamic === "") {
    //   error.dynamic = "This field is mandatory";
    //   isValid = false;
    // }

    if (
      (!file || file === "") &&
      (!iframeCustomCode || iframeCustomCode === "")
    ) {
      if (!file || file === "") {
        error.file = "Please give a template";
        isValid = false;
      }

      if (!iframeCustomCode || iframeCustomCode === "") {
        error.iframeCustomCode = "Please give a template";
        isValid = false;
      }
    }

    setErrors(error);
    return isValid;
  };

  return (
    <div>
      <SidebarWrapper>
        <DashboardHeader Heading={"Add Template"} />
        <SimpleNavbar type={"template"} />
        <SuccessMessage
          show={show}
          apiFailled={apiFailled}
          message={alertMessage}
          onClose={() => {
            setShow(false);
          }}
        />
        <div className="w-3/4 mx-auto md:w-full px-14">
          <div className="my-10 relative">
            <Input
              type="InputWithLabel"
              label="Template Name"
              placeholder="Template Name"
              inputType="text"
              value={name}
              disable={true}
            />
            <p className="text-red-500 text-sm mt-0.5 absolute top-full">
              {errors.name}
            </p>
          </div>
          <div className="my-10 relative">
            <Input
              type="NewsLetter"
              btnText="Upload"
              btnText2="Remove"
              label="Template HTML"
              placeholder="Upload your HTML"
              onClick={fileTaker}
              onClick2={FileRemover}
              disable={true}
              value={htmlFileName}
            />
            <p className="text-red-500 text-sm mt-0.5 absolute top-full">
              {errors.file}
            </p>
            <input
              type="file"
              className="w-0 h-0 absolute"
              onChange={(e) => {
                fileUploaded(
                  e.target.files[0],
                  URL.createObjectURL(e.target.files[0])
                );
                errors.file = "";
                errors.iframeCustomCode = "";
              }}
              ref={htmlUpload}
            />
          </div>
          <div className="my-10 relative">
            <Input
              type="InputWithLabel"
              label="Email Subject"
              placeholder="Email Subject"
              inputType="email"
              value={subject}
              onChange={(e) => {
                setSubject(e);
                errors.subject = "";
              }}
            />
            <p className="text-red-500 text-sm mt-0.5 absolute top-full">
              {errors.subject}
            </p>
          </div>
          <div className="my-10 relative">
            <Input
              type="TextareaAvtarAction"
              placeholder="Paste Your HTML here..."
              btnText="Post"
              onChange={(e) => {
                setCode(e);
                errors.file = "";
                errors.iframeCustomCode = "";
              }}
              value={iframeCustomCode === "" ? "" : iframeCustomCode}
              check={htmlFileName === "" ? false : true}
            />
            <p className="text-red-500 text-sm mt-0.5 absolute top-full">
              {errors.iframeCustomCode}
            </p>
          </div>
          <div className="my-10 relative">
            <div className="block text-sm font-medium text-gray-700">
              Template Preview
            </div>
            <div className="mt-1">
              <iframe
                src={previewFile === "" ? null : previewFile}
                srcDoc={iframeCustomCode === "" ? null : iframeCustomCode}
                className="rounded-lg w-full border border-gray-300 text-indigo-600 focus:ring-indigo-500"
              ></iframe>
            </div>
          </div>
          <div className="my-10 relative">
            <Input
              type="InputWithLabel"
              label="Dynamic Field"
              placeholder="NAME,PHONE,ADDRESS"
              inputType="text"
              value={dynamic}
              onChange={(e) => {
                setDynamic(e);
                errors.dynamic = "";
              }}
            />
            <p className="text-red-500 text-sm mt-0.5 absolute top-full">
              {errors.dynamic}
            </p>
          </div>
        </div>
        <div className="mx-auto mb-10">
          {" "}
          <Button
            type={"smallButton"}
            buttonText={"Save"}
            onClick={editTemplate}
          />
        </div>
      </SidebarWrapper>
    </div>
  );
};

export default Templatesdata;
