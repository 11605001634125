import { Fragment } from "react";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { Bars3Icon, BellIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { Link, useLocation } from "react-router-dom";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const SimpleNavbar = (props) => {
  const { pathname } = useLocation();
  // return (
  //   <Disclosure as="nav" className="bg-white border-b border-solid border-gray-300 sticky top-14">
  //     {({ open }) => (
  //       <>
  //         <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
  //           <div className="flex h-16 justify-between">
  //             <div className="flex">
  //               {/* <div className="flex flex-shrink-0 items-center">
  //                 <img
  //                   className="block h-8 w-auto lg:hidden"
  //                   src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
  //                   alt="Your Company"
  //                 />
  //                 <img
  //                   className="hidden h-8 w-auto lg:block"
  //                   src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
  //                   alt="Your Company"
  //                 />
  //               </div> */}
  //               <div className="hidden sm:ml-6 sm:flex sm:space-x-8">
  //                 {/* Current: "border-indigo-500 text-gray-900", Default: "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700" */}
  //                 <a
  //                   href="/template"
  //                   className={classNames(
  //                     pathname==="/template"? "inline-flex items-center border-b-2 border-indigo-500 px-1 pt-1 text-sm font-medium text-gray-900" : "inline-flex items-center px-1 pt-1 text-sm font-medium text-gray-900"

  //                   )}
  //                 >
  //                   Add New
  //                 </a>
  //                 <a
  //                   href="/edittemplate"
  //                   className={classNames(
  //                     pathname==="/edittemplate"? "inline-flex items-center border-b-2 border-indigo-500 px-1 pt-1 text-sm font-medium text-gray-900" : "inline-flex items-center px-1 pt-1 text-sm font-medium text-gray-900"

  //                   )}
  //                 >
  //                   Edit
  //                 </a>
  //                 {/* <a
  //                   href="#"
  //                   className="inline-flex items-center border-b-2 border-transparent px-1 pt-1 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
  //                 >
  //                   Projects
  //                 </a>
  //                 <a
  //                   href="#"
  //                   className="inline-flex items-center border-b-2 border-transparent px-1 pt-1 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
  //                 >
  //                   Calendar
  //                 </a> */}
  //               </div>
  //             </div>
  //             {/* <div className="hidden sm:ml-6 sm:flex sm:items-center">
  //               <button
  //                 type="button"
  //                 className="rounded-full bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
  //               >
  //                 <span className="sr-only">View notifications</span>
  //                 <BellIcon className="h-6 w-6" aria-hidden="true" />
  //               </button>

  //               Profile dropdown
  //               <Menu as="div" className="relative ml-3">
  //                 <div>
  //                   <Menu.Button className="flex rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
  //                     <span className="sr-only">Open user menu</span>
  //                     <img
  //                       className="h-8 w-8 rounded-full"
  //                       src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
  //                       alt=""
  //                     />
  //                   </Menu.Button>
  //                 </div>
  //                 <Transition
  //                   as={Fragment}
  //                   enter="transition ease-out duration-200"
  //                   enterFrom="transform opacity-0 scale-95"
  //                   enterTo="transform opacity-100 scale-100"
  //                   leave="transition ease-in duration-75"
  //                   leaveFrom="transform opacity-100 scale-100"
  //                   leaveTo="transform opacity-0 scale-95"
  //                 >
  //                   <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
  //                     <Menu.Item>
  //                       {({ active }) => (
  //                         <a
  //                           href="#"
  //                           className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}
  //                         >
  //                           Your Profile
  //                         </a>
  //                       )}
  //                     </Menu.Item>
  //                     <Menu.Item>
  //                       {({ active }) => (
  //                         <a
  //                           href="#"
  //                           className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}
  //                         >
  //                           Settings
  //                         </a>
  //                       )}
  //                     </Menu.Item>
  //                     <Menu.Item>
  //                       {({ active }) => (
  //                         <a
  //                           href="#"
  //                           className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}
  //                         >
  //                           Sign out
  //                         </a>
  //                       )}
  //                     </Menu.Item>
  //                   </Menu.Items>
  //                 </Transition>
  //               </Menu>
  //             </div> */}
  //             <div className="-mr-2 flex items-center sm:hidden">
  //               {/* Mobile menu button */}
  //               <Disclosure.Button className="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
  //                 <span className="sr-only">Open main menu</span>
  //                 {open ? (
  //                   <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
  //                 ) : (
  //                   <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
  //                 )}
  //               </Disclosure.Button>
  //             </div>
  //           </div>
  //         </div>

  //         <Disclosure.Panel className="sm:hidden">
  //           <div className="space-y-1 pt-2 pb-3">
  //             {/* Current: "bg-indigo-50 border-indigo-500 text-indigo-700", Default: "border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700" */}
  //             <Disclosure.Button
  //               as="a"
  //               href="/template"
  //               className="block border-l-4 border-indigo-500 bg-indigo-50 py-2 pl-3 pr-4 text-base font-medium text-indigo-700"
  //             >
  //               Add New
  //             </Disclosure.Button>
  //             <Disclosure.Button
  //               as="a"
  //               href="/edittemplate"
  //               className="block border-l-4 border-transparent py-2 pl-3 pr-4 text-base font-medium text-gray-500 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-700"
  //             >
  //               Edit
  //             </Disclosure.Button>
  //             {/* <Disclosure.Button
  //               as="a"
  //               href="#"
  //               className="block border-l-4 border-transparent py-2 pl-3 pr-4 text-base font-medium text-gray-500 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-700"
  //             >
  //               Projects
  //             </Disclosure.Button>
  //             <Disclosure.Button
  //               as="a"
  //               href="#"
  //               className="block border-l-4 border-transparent py-2 pl-3 pr-4 text-base font-medium text-gray-500 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-700"
  //             >
  //               Calendar
  //             </Disclosure.Button> */}
  //           </div>
  //           {/* <div className="border-t border-gray-200 pt-4 pb-3">
  //             <div className="flex items-center px-4">
  //               <div className="flex-shrink-0">
  //                 <img
  //                   className="h-10 w-10 rounded-full"
  //                   src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
  //                   alt=""
  //                 />
  //               </div>
  //               <div className="ml-3">
  //                 <div className="text-base font-medium text-gray-800">Tom Cook</div>
  //                 <div className="text-sm font-medium text-gray-500">tom@example.com</div>
  //               </div>
  //               <button
  //                 type="button"
  //                 className="ml-auto flex-shrink-0 rounded-full bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
  //               >
  //                 <span className="sr-only">View notifications</span>
  //                 <BellIcon className="h-6 w-6" aria-hidden="true" />
  //               </button>
  //             </div>
  //             <div className="mt-3 space-y-1">
  //               <Disclosure.Button
  //                 as="a"
  //                 href="#"
  //                 className="block px-4 py-2 text-base font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-800"
  //               >
  //                 Your Profile
  //               </Disclosure.Button>
  //               <Disclosure.Button
  //                 as="a"
  //                 href="#"
  //                 className="block px-4 py-2 text-base font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-800"
  //               >
  //                 Settings
  //               </Disclosure.Button>
  //               <Disclosure.Button
  //                 as="a"
  //                 href="#"
  //                 className="block px-4 py-2 text-base font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-800"
  //               >
  //                 Sign out
  //               </Disclosure.Button>
  //             </div>
  //           </div> */}
  //         </Disclosure.Panel>
  //       </>
  //     )}
  //   </Disclosure>
  // )

  const navType = (type) => {
    switch (type) {
      case "template": {
        return (
          <Disclosure
            as="nav"
            className="bg-white border-b border-solid border-gray-300 sticky top-14 z-20"
          >
            {({ open }) => (
              <>
                <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                  <div className="flex h-16 justify-between">
                    <div className="flex">
                     
                      <div className="hidden sm:ml-6 sm:flex sm:space-x-8">
                        {/* Current: "border-indigo-500 text-gray-900", Default: "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700" */}
                        <Link
                          to={"/templatelist"}
                          className={classNames(
                            pathname === "/templatelist"
                              ? "inline-flex items-center border-b-2 border-indigo-500 px-1 pt-1 text-sm font-medium text-gray-900"
                              : "inline-flex items-center px-1 pt-1 text-sm font-medium text-gray-900"
                          )}
                        >
                          Templates List
                        </Link>
                        <Link
                          to={"/template"}
                          className={classNames(
                            pathname === "/template"
                              ? "inline-flex items-center border-b-2 border-indigo-500 px-1 pt-1 text-sm font-medium text-gray-900"
                              : "inline-flex items-center px-1 pt-1 text-sm font-medium text-gray-900"
                          )}
                        >
                          Add New
                        </Link>
                        <div
                          
                          className={classNames(
                            pathname.includes('edittemplate')
                              ? "inline-flex items-center border-b-2 border-indigo-500 px-1 pt-1 text-sm font-medium text-gray-900"
                              : "select-none inline-flex items-center px-1 pt-1 text-sm font-medium text-gray-600/50"
                          )}
                        >
                          Update
                        </div>
                        {/* <a
                          href="#"
                          className="inline-flex items-center border-b-2 border-transparent px-1 pt-1 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
                        >
                          Projects
                        </a>
                        <a
                          href="#"
                          className="inline-flex items-center border-b-2 border-transparent px-1 pt-1 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
                        >
                          Calendar
                        </a> */}
                      </div>
                    </div>
                    {/* <div className="hidden sm:ml-6 sm:flex sm:items-center">
                      <button
                        type="button"
                        className="rounded-full bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      >
                        <span className="sr-only">View notifications</span>
                        <BellIcon className="h-6 w-6" aria-hidden="true" />
                      </button>
      
                      Profile dropdown
                      <Menu as="div" className="relative ml-3">
                        <div>
                          <Menu.Button className="flex rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                            <span className="sr-only">Open user menu</span>
                            <img
                              className="h-8 w-8 rounded-full"
                              src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                              alt=""
                            />
                          </Menu.Button>
                        </div>
                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-200"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                            <Menu.Item>
                              {({ active }) => (
                                <a
                                  href="#"
                                  className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}
                                >
                                  Your Profile
                                </a>
                              )}
                            </Menu.Item>
                            <Menu.Item>
                              {({ active }) => (
                                <a
                                  href="#"
                                  className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}
                                >
                                  Settings
                                </a>
                              )}
                            </Menu.Item>
                            <Menu.Item>
                              {({ active }) => (
                                <a
                                  href="#"
                                  className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}
                                >
                                  Sign out
                                </a>
                              )}
                            </Menu.Item>
                          </Menu.Items>
                        </Transition>
                      </Menu>
                    </div> */}
                    <div className="-mr-2 flex items-center sm:hidden">
                      {/* Mobile menu button */}
                      <Disclosure.Button className="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                        <span className="sr-only">Open main menu</span>
                        {open ? (
                          <XMarkIcon
                            className="block h-6 w-6"
                            aria-hidden="true"
                          />
                        ) : (
                          <Bars3Icon
                            className="block h-6 w-6"
                            aria-hidden="true"
                          />
                        )}
                      </Disclosure.Button>
                    </div>
                  </div>
                </div>

                <Disclosure.Panel className="sm:hidden">
                  <div className="space-y-1 pt-2 pb-3">
                    {/* Current: "bg-indigo-50 border-indigo-500 text-indigo-700", Default: "border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700" */}
                    <Disclosure.Button
                      as="a"
                      href="/templatelist"
                      className="block border-l-4 border-indigo-500 bg-indigo-50 py-2 pl-3 pr-4 text-base font-medium text-indigo-700"
                    >
                      Templates List
                    </Disclosure.Button>
                    <Disclosure.Button
                      as="a"
                      href="/template"
                      className="block border-l-4 border-indigo-500 bg-indigo-50 py-2 pl-3 pr-4 text-base font-medium text-indigo-700"
                    >
                      Add New
                    </Disclosure.Button>
                    <Disclosure.Button
                      as="a"
                      href=""
                      className="block border-l-4 border-transparent py-2 pl-3 pr-4 text-base font-medium text-gray-500 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-700"
                    >
                      Edit
                    </Disclosure.Button>
                    {/* <Disclosure.Button
                      as="a"
                      href="#"
                      className="block border-l-4 border-transparent py-2 pl-3 pr-4 text-base font-medium text-gray-500 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-700"
                    >
                      Projects
                    </Disclosure.Button>
                    <Disclosure.Button
                      as="a"
                      href="#"
                      className="block border-l-4 border-transparent py-2 pl-3 pr-4 text-base font-medium text-gray-500 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-700"
                    >
                      Calendar
                    </Disclosure.Button> */}
                  </div>
                  {/* <div className="border-t border-gray-200 pt-4 pb-3">
                    <div className="flex items-center px-4">
                      <div className="flex-shrink-0">
                        <img
                          className="h-10 w-10 rounded-full"
                          src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                          alt=""
                        />
                      </div>
                      <div className="ml-3">
                        <div className="text-base font-medium text-gray-800">Tom Cook</div>
                        <div className="text-sm font-medium text-gray-500">tom@example.com</div>
                      </div>
                      <button
                        type="button"
                        className="ml-auto flex-shrink-0 rounded-full bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      >
                        <span className="sr-only">View notifications</span>
                        <BellIcon className="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>
                    <div className="mt-3 space-y-1">
                      <Disclosure.Button
                        as="a"
                        href="#"
                        className="block px-4 py-2 text-base font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-800"
                      >
                        Your Profile
                      </Disclosure.Button>
                      <Disclosure.Button
                        as="a"
                        href="#"
                        className="block px-4 py-2 text-base font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-800"
                      >
                        Settings
                      </Disclosure.Button>
                      <Disclosure.Button
                        as="a"
                        href="#"
                        className="block px-4 py-2 text-base font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-800"
                      >
                        Sign out
                      </Disclosure.Button>
                    </div>
                  </div> */}
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
        );
      }

      case "bulkEmail": {
        return (
          <Disclosure
            as="nav"
            className="bg-white border-b border-solid border-gray-300 sticky top-14"
          >
            {({ open }) => (
              <>
                <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                  <div className="flex h-14 sm:h-16 justify-between">
                    <div className="flex items-center">
                      <div className="sm:ml-6 sm:flex sm:space-x-8">
                        <h1 className="text-xl sm:text-2xl font-semibold"> Send Bulk Email </h1>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </Disclosure>
        );
      }
      
      default: {
        console.log("Invalid Choice");
      }
    }
  };

  return navType(props.type);
};
export default SimpleNavbar;
