import { Fragment, useState } from 'react'
import { Dialog, Menu, Transition } from '@headlessui/react'
import {
  Bars3BottomLeftIcon,
  BellIcon,
  CalendarIcon,
  ChartBarIcon,
  FolderIcon,
  HomeIcon,
  InboxIcon,
  UsersIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline'
import { MagnifyingGlassIcon } from '@heroicons/react/20/solid'
import Button from './Button'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'

const navigation = [
  { name: 'Dashboard', href: '/dashboard', hrefPart1:"", hrefPart2:"", icon: HomeIcon, current: true },
  { name: 'Templates', href:'/templatelist', hrefPart1:"/template", hrefPart2:"/edittemplate/", icon: UsersIcon, current: false },
  { name: 'Send Bulk Email', href: '/bulkemail', hrefPart1:"", hrefPart2:"", icon: FolderIcon, current: false },
  { name: 'Scheduled Mails', href: '/scheduledmail', hrefPart1:"", hrefPart2:"", icon: CalendarIcon, current: false },
  { name: 'Mail History', href: '/mailhistory', hrefPart1:"", hrefPart2:"", icon: InboxIcon, current: false },
//   { name: 'Reports', href: '#', icon: ChartBarIcon, current: false },
]
const userNavigation = [
  { name: 'Your Profile', href: '#' },
  { name: 'Settings', href: '#' },
  { name: 'Sign out', href: '#' },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const SidebarWrapper=(props)=> {

  const {pathname} = useLocation();
  let { id } = useParams();

  const [sidebarOpen, setSidebarOpen] = useState(false)
  const navigate = useNavigate()
  
const Logout=()=>{
  localStorage.clear()
  navigate("/")
}

  return (
    <>
      <div>
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog as="div" className="relative z-40 md:hidden" onClose={setSidebarOpen}>
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
            </Transition.Child>

            <div className="fixed inset-0 z-40 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative flex w-full max-w-xs flex-1 flex-col bg-white pt-5 pb-4">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute top-0 right-0 -mr-12 pt-2">
                      <button
                        type="button"
                        className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                        onClick={() => setSidebarOpen(false)}
                      >
                        <span className="sr-only">Close sidebar</span>
                        <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
                      </button>
                    </div>
                  </Transition.Child>
                  <div className="flex flex-shrink-0 items-center px-4">
                    <img
                      className="h-8 w-auto"
                      src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
                      alt="Your Company"
                    />
                  </div>
                  <div className="mt-5 h-0 flex-1 overflow-y-auto">
                    <nav className="space-y-1 px-2">
                      {navigation.map((item) => (
                        <Link
                          key={item.name}
                          to={item.href}
                          className={classNames(
                            item.href===pathname || item.hrefPart1 === pathname || item.hrefPart2+id === pathname 
                              ? 'bg-gray-100 text-gray-900'
                              : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900',
                            'group rounded-md py-2 px-2 flex items-center text-base font-medium'
                          )}
                        >
                          <item.icon
                            className={classNames(
                              item.href===pathname || item.hrefPart1 === pathname || item.hrefPart2+id === pathname ? 'text-gray-500' : 'text-gray-400 group-hover:text-gray-500',
                              'mr-4 flex-shrink-0 h-6 w-6'
                            )}
                            aria-hidden="true"
                          />
                          {item.name}
                        </Link>
                      ))}
                    </nav>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
              <div className="w-14 flex-shrink-0">
                {/* Dummy element to force sidebar to shrink to fit close icon */}
              </div>
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div className="hidden md:fixed md:inset-y-0 md:flex md:w-64 md:flex-col">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex flex-grow flex-col overflow-y-auto border-r border-gray-200 bg-white pt-7">
            <div className="flex flex-shrink-0 items-center px-4">
              <img
                className="h-7 w-auto"
                src="/assets/sasoneLogo/SASOne.svg"
                alt="Your Company"
              />
            </div>
            <div className="mt-5 flex flex-grow flex-col">
              <nav className="space-y-1 px-2">
                {navigation.map((item) => (
                  <Link
                    key={item.name}
                    to={item.href}
                    className={classNames(
                      item.href===pathname || item.hrefPart1 === pathname || item.hrefPart2+id === pathname ? 'bg-gray-200 text-gray-900' : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900',
                      'group rounded-md py-2 px-2 flex items-center text-sm font-medium'
                    )}
                  >
                    <item.icon
                      className={classNames(
                        item.href===pathname || item.hrefPart1 === pathname || item.hrefPart2+id === pathname? 'text-gray-500' : 'text-gray-400 group-hover:text-gray-500',
                        'mr-3 flex-shrink-0 h-6 w-6'
                      )}
                      aria-hidden="true"
                    />
                    {item.name}
                  </Link>
                  
                ))}
               
              </nav>
           <div className='pt-40 pl-7' onClick={()=>Logout()}><Button type={"smallButton"} buttonText={"Log Out"}/></div>
            </div>   
          </div>         
        </div>

        <div className="md:pl-64 mt-2">
          <div className="mx-auto flex max-w-5xl flex-col md:px-8 xl:px-0">
            

            
           {props.children}
          
          </div>
        </div>
      </div>
    </>
  )
}
export default SidebarWrapper;