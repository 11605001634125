import { TailSpin } from "react-loader-spinner";
import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";

const Button = (props) => {
  const buttonType = (type) => {
    switch (type) {
      case "smallButton":
        return (
          <>
            <button
              type="button"
              onClick={props.onClick}
              className="inline-flex items-center rounded border border-transparent bg-purpleButton px-8 py-2 text-base font-normal leading-4 text-white shadow-sm hover:bg-Indigo-200 focus:outline-none "
            >
              {props.loading ? (
                <TailSpin height="18 " width="18" color="white" />
              ) : (
                props.buttonText
              )}
            </button>
          </>
        );
      case "pinkLong":
        return (
          <button
            type="button"
            onClick={props.onClick}
            className="inline-flex items-center rounded border border-transparent bg-pinkButton px-6 py-1.5 text-xs font-medium text-white shadow-sm hover:bg-lightPink focus:outline-none "
          >
            {props.buttonText}
          </button>
        );
      case "greenLong":
        return (
          <button
            type="button"
            onClick={props.onClick}
            className="inline-flex items-center rounded border border-transparent bg-greenButton px-6 py-1.5 text-xs font-medium text-white shadow-sm hover:bg-lightGreen focus:outline-none "
          >
            {props.loading ? (
              <TailSpin height="18 " width="18" color="white" />
            ) : (
              props.buttonText
            )}
          </button>
        );
      case "redFilled":
        return (
          <>
            <button
              onClick={props.onClick}
              type="button"
              className="inline-flex items-center rounded-md border border-transparent bg-redHeader px-5 py-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-red-700 focus:outline-none "
            >
              {props.loading ? (
                <TailSpin height="20" width="20" color="white" />
              ) : (
                props.buttonText
              )}
            </button>
          </>
        );
      case "whiteButton":
        return (
          <>
            <button
              type="button"
              onClick={props.onClick}
              className="inline-flex items-center rounded-md border border-gray bg-white px-5 py-2 text-sm font-medium leading-4 text-gray shadow-sm hover:bg-Gray focus:outline-none "
            >
              {props.buttonText}
            </button>
          </>
        );
      case "greenFilled":
        return (
          <>
            <button
              type="button"
              onClick={props.onClick}
              className="inline-flex items-center rounded-md border border-transparent bg-greenButton px-5 py-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-lightGreen focus:outline-none"
            >
              {props.loading ? (
                <TailSpin height="18 " width="18" color="white" />
              ) : (
                props.buttonText
              )}
            </button>
          </>
        );
      case "blueFilled":
        return (
          <>
            <button
              type="button"
              onClick={props.onClick}
              className={`inline-flex items-center rounded-md border border-transparent px-5 py-2.5 text-base font-medium leading-4 text-white shadow-sm focus:outline-none ${props.check ? "bg-gray-400 cursor-not-allowed" : "bg-indigo-600 hover:bg-indigo-700"} `}
              disabled={props.check ? true : false}
            >
              {props.loading ? (
                <TailSpin height="18 " width="18" color="white" />
              ) : (
                props.buttonText
              )}
            </button>
          </>
        );
      case "SearchButton":
        return (
          <>
            <button
              type="button"
              onClick={props.onClick}
              className="inline-flex items-center rounded border border-transparent bg-purpleButton px-5 h-10 sm:h-9 text-lg font-medium leading-4 text-white shadow-sm hover:bg-Indigo-100 focus:outline-none "
            >
              {props.loading ? (
                <TailSpin height="18 " width="18" color="white" />
              ) : (
                props.buttonText
              )}
            </button>
          </>
        );
        case "magnifySearch":
          return (
              <button
              type="submit"
              onClick={props.onClick}
              className="inline-flex justify-center rounded-full border border-gray-300 bg-white px-3 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-pink-500 focus:ring-offset-2"
            >
              <MagnifyingGlassIcon
                className="h-6 w-4 text-gray-400"
                aria-hidden="true"
              />
              <span className="sr-only">Search</span>
            </button> 
          );
      
          case "dateButton":{
              return (
                <>
                  <button
                    type="button"
                    onClick={props.onClick}
                    className="inline-flex items-center rounded border border-transparent bg-purpleButton px-6 lg:px-8 py-2.5 text-base font-normal leading-4 text-white shadow-sm hover:bg-Indigo-200 focus:outline-none"
                  >
                    {props.loading ? (
                      <TailSpin height="18 " width="18" color="white" />
                    ) : (
                      props.buttonText
                    )}
                  </button>
                </>
              );
          }

      case "date":{
        return (
          <div className="flex items-center">
            <label
              htmlFor={props.dateLabel}
              className="w-10 block text-sm font-medium text-gray-700 pt-1"
            >
              {props.label}
            </label>
            <div className="mt-2">
              <input
                type="date"
                id={props.dateLabel}
                value={props.value}
                onChange={props.onChange}
                name={props.dateLabel}
                required
                className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm "
              />
            </div>
          </div>
        );
      }
          default:
        console.log("Invalid type");
        break;
    }
  };
  return <div>{buttonType(props.type)}</div>;
};

export default Button;
