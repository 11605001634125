import React from "react";
import DashboardCard from "../Components/TailwindComponent/DashboardCard";
import DashboardHeader from "../Components/TailwindComponent/DashboardHeader";
import SidebarWrapper from "../Components/TailwindComponent/SidebarWrapper";
import {
  CursorArrowRaysIcon,
  EnvelopeOpenIcon,
  UsersIcon,
} from "@heroicons/react/24/outline";
import { RiMailCheckLine, RiMailCloseLine } from "react-icons/ri";
import { MdSchedule } from "react-icons/md";
import { useEffect, useState } from "react";
import DatePicker from "../Components/TailwindComponent/DatePicker";

const Dashboard = () => {
  const [data, setData] = useState({});
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  // useEffect(() => {
  //   defaultDate();
  // }, []);

  useEffect(() => {
    var date = new Date();
    date.setDate(date.getDate() - 30);

    let startDateformat =
      date.getDate().toString().length === 1
        ? "0" + date.getDate()
        : date.getDate();

    const monthFormat =
      date.getMonth() + 1 > 9
        ? date.getMonth() + 1
        : "0" + (date.getMonth() + 1);

    var finalDate =
      date.getFullYear() + "-" + monthFormat + "-" + startDateformat;
    setStartDate(finalDate);

    var todayDate = new Date();
    todayDate.setDate(todayDate.getDate());

    let endDateformat =
      todayDate.getDate().toString().length === 1
        ? "0" + todayDate.getDate()
        : todayDate.getDate();
    const todaymonthFormat =
      todayDate.getMonth() + 1 > 9
        ? todayDate.getMonth() + 1
        : "0" + (todayDate.getMonth() + 1);

    var finalToday =
      todayDate.getFullYear() + "-" + todaymonthFormat + "-" + endDateformat;
    setEndDate(finalToday);
    getDashboardInfo(finalDate, finalToday);
  }, []);

  const getDashboardInfo = async (end,today) => {
    let fetechedData = await fetch(
      "https://bulk.sasone.in/emailapi/v1/bulkemail/DashboardAPI",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token 8cb23c1e5efb27dbadefabe601226a4264f59daa",
        },
        body: JSON.stringify({
          StartDate: end ? end :startDate,
          EndDate: today ? today : endDate,
        }),
      }
    );

    let parsedData = await fetechedData.json();

    if (parsedData.status_code === 200 && parsedData.status === "Success") {
      setData(parsedData.data);
    }
  };

  // const defaultDate = () => {
  //   var date = new Date();
  //   date.setDate(date.getDate() - 30);

  //   let startDateformat =
  //     date.getDate().toString().length === 1
  //       ? "0" + date.getDate()
  //       : date.getDate();

  //   const monthFormat =
  //     date.getMonth() + 1 > 9
  //       ? date.getMonth() + 1
  //       : "0" + (date.getMonth() + 1);

  //   var finalDate =
  //     date.getFullYear() + "-" + monthFormat + "-" + startDateformat;
  //   setStartDate(finalDate);

  //   var todayDate = new Date();
  //   todayDate.setDate(todayDate.getDate());

  //   let endDateformat =
  //     todayDate.getDate().toString().length === 1
  //       ? "0" + todayDate.getDate()
  //       : todayDate.getDate();
  //   const todaymonthFormat =
  //     todayDate.getMonth() + 1 > 9
  //       ? todayDate.getMonth() + 1
  //       : "0" + (todayDate.getMonth() + 1);

  //   var finalToday =
  //     todayDate.getFullYear() + "-" + todaymonthFormat + "-" + endDateformat;
  //   setEndDate(finalToday);

  //   getDashboardInfo(finalDate, finalToday);
  // };

  const stats = [
    { id: 1, name: "Open", icon: EnvelopeOpenIcon, href: "/mailhistory" },
    { id: 2, name: "Delivered", icon: RiMailCheckLine, href: "/mailhistory" },
    { id: 3, name: "Undelivered", icon: RiMailCloseLine, href: "/mailhistory" },
    {
      id: 4,
      name: "Scheduled Mails",
      icon: MdSchedule,
      href: "/scheduledmail",
    },
  ];

  return (
    <div>
      <SidebarWrapper>
        <DashboardHeader Heading={"Dashboard"} />
        <div className="mt-4">
          <DatePicker
            endDate={endDate}
            startDate={startDate}
            StartOnChange={(e) => {
              setStartDate(e.target.value);
            }}
            EndOnChange={(e) => {
              setEndDate(e.target.value);
            }}
            onClickBtn={() => {
              getDashboardInfo();
            }}
          />
        </div>
        <main className="flex-1">
          <div className="py-1">
            {/* <div className="px-4 sm:px-6 md:px-0">
                            <h1 className="text-2xl font-semibold text-gray-900">Dashboard</h1>
                        </div> */}
            <div className="px-4 sm:px-6 md:px-0">
              {/* Replace with your content */}
              <div className="py-4">
                <div className="bg-slate-100 pt-2 px-5 pb-10">
                  <DashboardCard stats={stats} data={data} />
                </div>
              </div>
              {/* /End replace */}
            </div>
          </div>
        </main>
      </SidebarWrapper>
    </div>
  );
};

export default Dashboard;
