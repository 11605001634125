import { Fragment, useState } from "react";
import {
  FaceFrownIcon,
  FaceSmileIcon,
  FireIcon,
  HandThumbUpIcon,
  HeartIcon,
  PaperClipIcon,
  XMarkIcon,
} from "@heroicons/react/20/solid";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { BarsArrowUpIcon, UsersIcon } from "@heroicons/react/20/solid";
import Button from "./Button";

const Input = (props) => {
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const people = [
    {
      id: 1,
      name: "Wade Cooper",
      avatar:
        "https://images.unsplash.com/photo-1491528323818-fdd1faba62cc?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
    },
    {
      id: 2,
      name: "Arlene Mccoy",
      avatar:
        "https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-1.2.1&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
    },
    {
      id: 3,
      name: "Devon Webb",
      avatar:
        "https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.25&w=256&h=256&q=80",
    },
    {
      id: 4,
      name: "Tom Cook",
      avatar:
        "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
    },
    {
      id: 5,
      name: "Tanya Fox",
      avatar:
        "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
    },
    {
      id: 6,
      name: "Hellen Schmidt",
      avatar:
        "https://images.unsplash.com/photo-1487412720507-e7ab37603c6f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
    },
    {
      id: 7,
      name: "Caroline Schultz",
      avatar:
        "https://images.unsplash.com/photo-1568409938619-12e139227838?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
    },
    {
      id: 8,
      name: "Mason Heaney",
      avatar:
        "https://images.unsplash.com/photo-1531427186611-ecfd6d936c79?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
    },
    {
      id: 9,
      name: "Claudie Smitham",
      avatar:
        "https://images.unsplash.com/photo-1584486520270-19eca1efcce5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
    },
    {
      id: 10,
      name: "Emil Schaefer",
      avatar:
        "https://images.unsplash.com/photo-1561505457-3bcad021f8ee?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
    },
  ];

  const moods = [
    {
      name: "Excited",
      value: "excited",
      icon: FireIcon,
      iconColor: "text-white",
      bgColor: "bg-red-500",
    },
    {
      name: "Loved",
      value: "loved",
      icon: HeartIcon,
      iconColor: "text-white",
      bgColor: "bg-pink-400",
    },
    {
      name: "Happy",
      value: "happy",
      icon: FaceSmileIcon,
      iconColor: "text-white",
      bgColor: "bg-green-400",
    },
    {
      name: "Sad",
      value: "sad",
      icon: FaceFrownIcon,
      iconColor: "text-white",
      bgColor: "bg-yellow-400",
    },
    {
      name: "Thumbsy",
      value: "thumbsy",
      icon: HandThumbUpIcon,
      iconColor: "text-white",
      bgColor: "bg-blue-500",
    },
    {
      name: "I feel nothing",
      value: null,
      icon: XMarkIcon,
      iconColor: "text-gray-400",
      bgColor: "bg-transparent",
    },
  ];

  const [selected, setSelected] = useState(moods[5]);
  const [selectedDbl, setSelectedDbl] = useState(people[3]);

  const selectType = (type) => {
    switch (type) {
      case "InputWithLabel": {
        return (
          <div>
            <label
              htmlFor="email"
              className="block text-sm font-medium text-gray-700"
            >
              {props.label}
            </label>
            <div className="mt-1">
              <input
                type={props.inputType}
                name="email"
                id="email"
                value={props.value}
                onChange={(e) => props.onChange(e.target.value)}
                disabled={props.disable === true ? true : false}
                className={`${
                  props.disable === true
                    ? "bg-gray-200"
                    : "focus:border-indigo-500 focus:ring-indigo-500"
                } block w-full rounded-md border-gray-300 shadow-sm sm:text-sm py-2`}
                placeholder={props.placeholder}
              />
            </div>
          </div>
        );
      }

      case "InputDateOrTime": {
        return (
          <div>
            <label
              htmlFor="email"
              className="block text-sm font-medium text-gray-700"
            >
              {props.label}
            </label>
            <div className="mt-1">
              <input
                type={props.inputType}
                id="timeConcept"
                value={props.value}
                min={props.min}
                onChange={(e) => props.onChange(e.target.value)}
                disabled={props.disable === true ? true : false}
                className={`${
                  props.disable === true
                    ? "bg-gray-200"
                    : "focus:border-indigo-500 focus:ring-indigo-500"
                } block w-full rounded-md border-gray-300 shadow-sm sm:text-sm py-2`}
                placeholder={props.placeholder}
                onInput={props.onInput}
              />
            </div>
          </div>
        );
      }

      case "RadioGroup": {
        return (
          <div>
            <label className="text-base font-medium text-gray-900">
              {props.label}
            </label>
            <fieldset className="mt-4">
              <legend className="sr-only">Notification method</legend>
              <div className="space-y-4">
                {props.notificationMethods?.map((notificationMethod) => (
                  <div
                    key={notificationMethod.id}
                    className="flex items-center"
                  >
                    <input
                      id={notificationMethod.id}
                      name={props.radioName}
                      type="radio"
                      value={notificationMethod.id}
                      onChange={(e) => {
                        props.onChange(e.target);
                      }}
                      defaultChecked={notificationMethod.id === "uploadContact"}
                      className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                    />
                    <label
                      htmlFor={notificationMethod.id}
                      className="ml-3 block text-sm font-medium text-gray-700"
                    >
                      {notificationMethod.title}
                    </label>
                  </div>
                ))}
              </div>
            </fieldset>
          </div>
        );
      }

      case "TextareaAvtarAction": {
        return (
          <div className="flex items-start space-x-4">
            {/* <div className="flex-shrink-0">
                            <img
                                className="inline-block h-10 w-10 rounded-full"
                                src="https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                                alt=""
                            />
                        </div> */}
            <div className="min-w-0 flex-1">
              <form action="#" className="relative">
                <div
                  className={`${
                    props.check ? "bg-gray-300" : ""
                  } overflow-hidden rounded-lg border border-gray-300 shadow-sm focus-within:border-indigo-500 focus-within:ring-1 focus-within:ring-indigo-500`}
                >
                  <label htmlFor="comment" className="sr-only">
                    Add your comment
                  </label>
                  <textarea
                    rows={3}
                    name="comment"
                    id="comment"
                    onChange={(e) => {
                      props.onChange(e.target.value);
                    }}
                    className={`block w-full resize-none border-0 py-3 focus:ring-0 sm:text-sm ${
                      props.check ? "bg-gray-300" : ""
                    }`}
                    placeholder={props.placeholder}
                    value={props.value}
                    disabled={props.check ? true : false}
                  />

                  {/* Spacer element to match the height of the toolbar */}
                  <div className="py-2" aria-hidden="true">
                    {/* Matches height of button in toolbar (1px border + 36px content height) */}
                    <div className="py-px">
                      <div className="h-9" />
                    </div>
                  </div>
                </div>

                <div className="absolute inset-x-0 bottom-0 flex justify-between py-2 pl-3 pr-2">
                  <div className="flex items-center space-x-5">
                    <div className="flex items-center">
                      <button
                        type="button"
                        className="-m-2.5 flex h-10 w-10 items-center justify-center rounded-full text-gray-400 hover:text-gray-500"
                      >
                        <PaperClipIcon className="h-5 w-5" aria-hidden="true" />
                        <span className="sr-only">Attach a file</span>
                      </button>
                    </div>
                    <div className="flex items-center">
                      <Listbox value={selected} onChange={setSelected}>
                        {({ open }) => (
                          <>
                            <Listbox.Label className="sr-only">
                              {" "}
                              Your mood{" "}
                            </Listbox.Label>
                            <div className="relative">
                              <Listbox.Button className="relative -m-2.5 flex h-10 w-10 items-center justify-center rounded-full text-gray-400 hover:text-gray-500">
                                <span className="flex items-center justify-center">
                                  {selected.value === null ? (
                                    <span>
                                      <FaceSmileIcon
                                        className="h-5 w-5 flex-shrink-0"
                                        aria-hidden="true"
                                      />
                                      <span className="sr-only">
                                        {" "}
                                        Add your mood{" "}
                                      </span>
                                    </span>
                                  ) : (
                                    <span>
                                      <span
                                        className={classNames(
                                          selected.bgColor,
                                          "flex h-8 w-8 items-center justify-center rounded-full"
                                        )}
                                      >
                                        <selected.icon
                                          className="h-5 w-5 flex-shrink-0 text-white"
                                          aria-hidden="true"
                                        />
                                      </span>
                                      <span className="sr-only">
                                        {selected.name}
                                      </span>
                                    </span>
                                  )}
                                </span>
                              </Listbox.Button>

                              <Transition
                                show={open}
                                as={Fragment}
                                leave="transition ease-in duration-100"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                              >
                                <Listbox.Options className="absolute z-10 mt-1 -ml-6 w-60 rounded-lg bg-white py-3 text-base shadow ring-1 ring-black ring-opacity-5 focus:outline-none sm:ml-auto sm:w-64 sm:text-sm">
                                  {moods?.map((mood) => (
                                    <Listbox.Option
                                      key={mood.value}
                                      className={({ active }) =>
                                        classNames(
                                          active ? "bg-gray-100" : "bg-white",
                                          "relative cursor-default select-none py-2 px-3"
                                        )
                                      }
                                      value={mood}
                                    >
                                      <div className="flex items-center">
                                        <div
                                          className={classNames(
                                            mood.bgColor,
                                            "w-8 h-8 rounded-full flex items-center justify-center"
                                          )}
                                        >
                                          <mood.icon
                                            className={classNames(
                                              mood.iconColor,
                                              "flex-shrink-0 h-5 w-5"
                                            )}
                                            aria-hidden="true"
                                          />
                                        </div>
                                        <span className="ml-3 block truncate font-medium">
                                          {mood.name}
                                        </span>
                                      </div>
                                    </Listbox.Option>
                                  ))}
                                </Listbox.Options>
                              </Transition>
                            </div>
                          </>
                        )}
                      </Listbox>
                    </div>
                  </div>
                  {/* <div className="flex-shrink-0">
                                        <button
                                            type="submit"
                                            className="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                        >
                                            {props.btnText}
                                        </button>
                                    </div> */}
                </div>
              </form>
            </div>
          </div>
        );
      }

      case "NewsLetter": {
        return (
          <div className="bg-white">
            <label
              htmlFor="email"
              className="block text-sm font-medium text-gray-700"
            >
              {props.label}
            </label>
            <div className="mt-1 sm:flex">
              <input
                id="email-address"
                name="email"
                type="text"
                disabled={props.disable ? true : false}
                value={props.value}
                className="w-full rounded-md border-gray-300 px-3 py-1.5 placeholder-gray-500 focus:border-indigo-500 focus:ring-indigo-500 text-sm"
                placeholder={props.placeholder}
              />
              <div className="mt-3 rounded-md sm:mt-0 sm:ml-3 sm:flex-shrink-0 flex">
                <button
                  type="button"
                  className="flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-5 py-1.5 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  onClick={props.onClick}
                >
                  {props.btnText}
                </button>
                <button
                  type="button"
                  className={`${
                    props.value === ""
                      ? "bg-gray-300"
                      : "bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500"
                  } flex items-center justify-center rounded-md border border-transparent px-5 py-1.5 text-base font-medium text-white  focus:outline-none focus:ring-2  focus:ring-offset-2 ml-3`}
                  onClick={props.onClick2}
                  disabled={props.value === "" ? true : false}
                >
                  {props.btnText2}
                </button>
              </div>
            </div>
            {/* </div> */}
          </div>
        );
      }

      case "NewsLetterMod": {
        return (
          <div className="bg-white w-full">
            <div className="mt-8 sm:flex items-center justify-between w-full">
              <label htmlFor="email-address" className="sr-only">
                Email address
              </label>

              <input
                id="email-address"
                name="email"
                type="text"
                required
                className="w-full rounded-md border-gray-300 px-3 placeholder-gray-500 focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs font-sm"
                placeholder={props.placeholder}
              />
              <div className="mt-3 rounded-md shadow sm:mt-0 sm:ml-3 sm:flex-shrink-0">
                <button
                  type="submit"
                  className="flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-5 py-2 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none"
                >
                  {props.btnText}
                </button>
              </div>
            </div>
          </div>
        );
      }

      case "selectSimple": {
        return (
          <div>
            <label
              htmlFor="location"
              className="block text-sm font-medium text-gray-700"
            >
              {props.label}
            </label>
            <select
              id="location"
              name="location"
              className={`${
                props.label ? "mt-1" : ""
              } block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm`}
              // defaultValue={props.defValue}
              onChange={(e) => {
                props.onChange(e.target.value);
              }}
            >
              <option
                    value={""}
                    key={"unknown"}
                    selected={props.value === "" ? true : false}
                  >
                     - Select -
                  </option>
              {props.options?.map((item) => {
                return (
                  <option
                    value={item.EmailTemplateID}
                    key={item.EmailTemplateID}
                  >
                    {item.TemplateName||item.FromEmail}
                  </option>
                );
              })}
            </select>
          </div>
        );
      }

      case "selectDoubleArrow": {
        return (
          <Listbox value={selectedDbl} onChange={setSelectedDbl}>
            {({ open }) => (
              <>
                {/* <Listbox.Label className="block text-sm font-medium text-gray-700">
                  Assigned to
                </Listbox.Label> */}
                <div className="relative mt-1">
                  <Listbox.Button
                    className={`relative w-full cursor-default rounded-md border border-gray-300 py-2 pl-3 pr-10 text-left shadow-sm ${
                      props.disable
                        ? "bg-gray-200"
                        : "bg-white focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm"
                    }`}
                    onClick={(e) =>
                      props.disable ? "" : props.onClick(e.target.innerText)
                    }
                  >
                    <span className="flex items-center">
                      {/* <img
                        src={selectedDbl.avatar}
                        alt=""
                        className="h-6 w-6 flex-shrink-0 rounded-full"
                      /> */}
                      <span className="ml-3 block truncate">
                        {selectedDbl.name}
                      </span>
                    </span>
                    <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                      <ChevronUpDownIcon
                        className="h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                    </span>
                  </Listbox.Button>

                  <Transition
                    show={open}
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                    onClick={(e) => {
                      props.onClick(e.target.innerText);
                    }}
                  >
                    <Listbox.Options className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                      {props.internateData?.map((person) => (
                        <Listbox.Option
                          key={person.value}
                          className={({ active }) =>
                            classNames(
                              active
                                ? "text-white bg-indigo-600"
                                : "text-gray-900",
                              "relative cursor-default select-none py-2 pl-3 pr-9"
                            )
                          }
                          value={person}
                        >
                          {({ selectedDbl, active }) => (
                            <>
                              <div className="flex items-center">
                                {/* <img
                                  src={person.avatar}
                                  alt=""
                                  className="h-6 w-6 flex-shrink-0 rounded-full"
                                /> */}
                                <span
                                  className={classNames(
                                    selectedDbl
                                      ? "font-semibold"
                                      : "font-normal",
                                    "ml-3 block truncate"
                                  )}
                                >
                                  {person.name}
                                </span>
                              </div>

                              {selectedDbl ? (
                                <span
                                  className={classNames(
                                    active ? "text-white" : "text-indigo-600",
                                    "absolute inset-y-0 right-0 flex items-center pr-4"
                                  )}
                                >
                                  <CheckIcon
                                    className="h-5 w-5"
                                    aria-hidden="true"
                                  />
                                </span>
                              ) : null}
                            </>
                          )}
                        </Listbox.Option>
                      ))}
                    </Listbox.Options>
                  </Transition>
                </div>
              </>
            )}
          </Listbox>
        );
      }

      case "InputWithButton": {
        return (
          <div>
            <label
              htmlFor="email"
              className="block text-sm font-medium text-gray-700"
            >
              {props.label}
            </label>
            <div className="mt-1 flex rounded-md shadow-sm">
              <div className="relative flex flex-grow items-stretch focus-within:z-10">
                <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                  <UsersIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </div>
                <input
                  type={props.inputType}
                  name="email"
                  id="email"
                  defaultValue={props.value}
                  disabled
                  className={`${
                    props.disable ? "bg-gray-200" : ""
                  } block w-full rounded-none rounded-l-md border-gray-300 pl-10 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm`}
                  placeholder={props.placeholder}
                />
              </div>
              <button
                type="button"
                className="relative -ml-px inline-flex items-center space-x-2 rounded-r-md border border-gray-300 bg-gray-50 px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-100 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500"
                onClick={props.onClickBtn}
                disabled={props.disable}
              >
                <BarsArrowUpIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
                <span>{props.btnText}</span>
              </button>
            </div>
          </div>
        );
      }

      case "checkBox": {
        return (
          <fieldset className="space-y-5">
            <legend className="sr-only">Notifications</legend>
            {props.data?.map((item) => {
              return (
                <div className="relative flex items-start" key={item.value}>
                  <div className="flex h-5 items-center">
                    <input
                      id={item.value}
                      aria-describedby="comments-description"
                      value={item.value}
                      name="comments"
                      type="checkbox"
                      className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                      onChange={props.onCheckChange}
                    />
                  </div>
                  <div className="ml-3 text-sm">
                    <label
                      htmlFor="comments"
                      className="font-medium text-gray-700"
                    >
                      {item.label}
                    </label>
                    <p id="comments-description" className="text-gray-500">
                      {item.description}
                    </p>
                  </div>
                </div>
              );
            })}
            {/* <div className="relative flex items-start">
              <div className="flex h-5 items-center">
                <input
                  id="candidates"
                  aria-describedby="candidates-description"
                  name="candidates"
                  type="checkbox"
                  className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                />
              </div>
              <div className="ml-3 text-sm">
                <label htmlFor="candidates" className="font-medium text-gray-700">
                  Candidates
                </label>
                <p id="candidates-description" className="text-gray-500">
                  Get notified when a candidate applies for a job.
                </p>
              </div>
            </div>
            <div className="relative flex items-start">
              <div className="flex h-5 items-center">
                <input
                  id="offers"
                  aria-describedby="offers-description"
                  name="offers"
                  type="checkbox"
                  className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                />
              </div>
              <div className="ml-3 text-sm">
                <label htmlFor="offers" className="font-medium text-gray-700">
                  Offers
                </label>
                <p id="offers-description" className="text-gray-500">
                  Get notified when a candidate accepts or rejects an offer.
                </p>
              </div>
            </div> */}
          </fieldset>
        );
      }

      case "SearchBox": { 
        return (
          <div className="flex justify-end items-center">
            <input
              type="search"
              name="search"
              id="search"
              placeholder="Search..."
              className="w-56 block rounded-md border-gray-300 pl-4 focus:border-pink-500 focus:ring-pink-500 sm:text-sm"
              value={props.value}
              onChange={(e)=>{props.onChange(e.target.value)}}
            />

            <div className="ml-4">
              <Button type="magnifySearch" onClick={props.onClick} />
            </div>
          </div>
        );
      }

      case "date":{
        return(
          <div className="flex items-center">
          <label
            htmlFor={props.dateLabel}
            className="w-10 sm:w-auto mr-4 block text-sm font-medium text-gray-700 pt-0"
          >
            {props.label}
          </label>
          <div className="">
            <input
              type="date"
              id={props.dateLabel}
              value={props.value}
              onChange={props.onChange}
              name={props.dateLabel}
              min={props.min ? props.min : ""}
              className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
            />
          </div>
        </div>
        );
      }

      default: {
        console.log("Invalid Choice");
        break;
      }
    }
  };

  return selectType(props.type);
};

export default Input;
