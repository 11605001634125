import React, { useState, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { FcGoogle } from "react-icons/fc";
import SuccessMessage from "./SuccessMessage";
import { GoogleLogin } from "@react-oauth/google";
import { TailSpin } from "react-loader-spinner";


const SignInRegistration = () => {
  let navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [show, setShow] = useState(false);
  const [apiFailled, setApiFailled] = useState(false);
  const [alertMessage, setAlertMessage] = useState("Status Updated");
  const [showMess, setShowMess] = useState(false)
  const [loading,setLoading] = useState(false)
  

  const loginHandler = async (e) => {
    e.preventDefault();
    setLoading(true)
    let fetechedData = await fetch(
      "https://bulk.sasone.in/emailapi/v1/bulkemail/EmailLoginapi",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": "Token 8cb23c1e5efb27dbadefabe601226a4264f59daa",
        },
        body: JSON.stringify({
          EmailLoginID: email,
          Password: password,
        }),
      });

    let parseData = await fetechedData.json();

    if (parseData.message === "User Found successFully" && parseData.status_code === 200 && parseData.status === "Success") {
      localStorage.setItem("auth_token", parseData.data.EmailLoginID);
      localStorage.setItem("initials", parseData.data.FirstName.slice(0, 1));
      setLoading(false)
      navigate("/dashboard");
    } else {
      setAlertMessage("Username or Password Incorrect...!");
      setApiFailled(true);
      setShow(true);
      setLoading(false)
      // setTextMess(true)
    }

  };

  

  const onSuccess = (res) => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Token 8cb23c1e5efb27dbadefabe601226a4264f59daa");
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      "IdToken": res.credential
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch("https://bulk.sasone.in/emailapi/v1/bulkemail/GoogleLogin", requestOptions)
      .then(response => response.json())
      .then(result => {
        if (result.status_code === 200 && result.status === "Success" && result.message === "Login Successful") {
          localStorage.setItem("initials", result.data.FirstName.slice(0, 1))
          localStorage.setItem("auth_token", result.data.EmailLoginID)
          navigate("/dashboard");
        }
        else {
          setShowMess(true)
          setTimeout(() => {
            setShowMess(false)
          }, 2000);
        }
      })
      .catch(error => console.log('error', error));
  }

  // 
  useMemo(() => {
    setTimeout(() => {
      setShow(false);
    }, 4000);
  }, [show]);

  // const onSuccess = (response) => {
  //   console.log(response);
  // };
  const errorMessage = (error) => {
    console.log(error);
  };

  return (
    <>
      <SuccessMessage
        show={show}
        apiFailled={apiFailled}
        message={alertMessage}
        onClose={() => {
          setShow(false);
        }}
      />
      <div className="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">
            Sign in to your account
          </h2>
        </div>

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            <form
              className=""
              onSubmit={(e) => {
                loginHandler(e);
              }}
            >
              <div className="mb-4">
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700"
                >
                  Email address
                </label>
                <div className="mt-1">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    required
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                    className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  />
                </div>
              </div>

              <div>
                <label
                  htmlFor="password"
                  className="block text-sm font-medium text-gray-700"
                >
                  Password
                </label>
                <div className="mt-1">
                  <input
                    id="password"
                    name="password"
                    type="password"
                    autoComplete="current-password"
                    required
                    onChange={(e) => {
                      setPassword(e.target.value);
                    }}
                    className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  />
                </div>
              </div>
            
              <div>
             
                {loading?<div className="mt-8 flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"> <TailSpin height="18 " width="18" color="white" /></div>:<button
                  type="submit"
                  className="mt-8 flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                >
                  Sign in
                </button>}
              </div>
             
            </form>
            <div className="mt-6">
              <div className="relative">
                <div className="absolute inset-0 flex items-center">
                  <div className="w-full border-t border-gray-300" />
                </div>
                <div className="relative flex justify-center text-sm">
                  <span className="bg-white px-2 text-gray-500">
                    Or continue with
                  </span>
                </div>
              </div>

              <div>

                <div className="mt-4 flex justify-center">
                  <GoogleLogin onSuccess={onSuccess} onError={errorMessage} theme="filled_blue" type="standard" />
                 
                </div>
                {showMess&&<div className="pt-2 text-sm text-red-500 font-medium">Email not registered.Please login with different email.</div>}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default SignInRegistration;
